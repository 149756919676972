import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
// import { Redirect } from "react-router-dom";
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
// @material-ui/icons
// import Face from "@material-ui/icons/Face";
import Email from '@material-ui/icons/Email';
// import LockOutline from "@material-ui/icons/LockOutline";
import Swal from 'sweetalert2';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';
import auth from 'utils/auth';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden',
      username: '',
      password: '',
    };
  }

  componentWillMount() {
    if (auth.isAuthenticated()) {
      // todo if homeowner logged-in should redirect to calendar
      this.props.history.push('/admin/booking-calendar');
    }
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: '' });
      }.bind(this),
      700,
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  submitLogin = async () => {
    try {
      await this.props.user.signIn(this.state.username, this.state.password);
      Swal.fire({
        type: 'success',
        title: 'เข้าสู่ระบบสำเร็จ',
        text: 'ยินดีต้อนรับ',
      }).then(result => {
        if (result.value) {
          if (auth.getUserRole() === 'user') {
            this.props.history.push('/admin/house');

          } else {
            this.props.history.push('/admin/booking-calendar');
          }
        }
      });
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: 'เข้าสู่ระบบไม่สำเร็จ',
        text: error.message,
      });
    }
  };

  // printError(code) {
  //   console.log('code',code);
  //   let message = 'ไม่สามารถบันทึกข้อมูลได้';
  //   switch (code) {
  //     case 'username_password_required':
  //       message = 'กรุณากรอกข้อมูลให้ถูกต้องครบถ้วน';
  //       break;
  //     case 'authentication_failed':
  //       message = 'ข้อมูลไม่ถูกต้อง';
  //       break;
  //     default:
  //       break;
  //   }
  //   return message;
  // }

  onTextChange = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose">
                  <h4 className={classes.cardTitle}>Log in</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="Username..."
                    id="username"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: event =>
                        this.onTextChange('username', event.target.value),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: event =>
                        this.onTextChange('password', event.target.value),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      type: 'password',
                      autoComplete: 'off',
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button
                    onClick={this.submitLogin}
                    color="rose"
                    simple
                    size="lg"
                    block>
                    Let{"'"}s Go
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const page = inject('user')(observer(LoginPage));
export default withStyles(loginPageStyle)(page);
