import { extendObservable } from 'mobx';
import http from '../utils/http';
// import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';


const initialPriceSetting = {
  houseId: null,
  price: 0,
  specialPrice: 0,
  pricePerPerson: 0,
  dayPrice: 0,
  fridayPrice: 0,
  saturdayPrice: 0,
  sundayPrice: 0,
  holidayPrice: 0,
  startDate: "",
  endDate: ""
}

export class PriceSettingStore {
  constructor() {
    extendObservable(this, {
      loading: false,
      priceSetting: initialPriceSetting
    });
  }

  changeField(task, data) {
    this.priceSetting[task] = data;
  }

  initPriceSetting() {
    this.priceSetting = initialPriceSetting;
  }

  async createPriceSetting() {
    this.loading = true;
    const data = snakecaseKeys(this.priceSetting);
    try {
      let url = `/price-settings`;
      let response = await http.post(url, data);
      return response;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

}

const priceSettingStore = new PriceSettingStore();
export default priceSettingStore;