export const VALIDATE_TYPES = {
  EMPTY: 'empty',
  MAX_LENGTH: 'max_length',
  NUMBER: 'number',
};

class ValidateControl {
  verifyEmpty(value) {
    return value !== undefined && value !== '' && value !== null;
  }

  verifyMaxLength(value, maxLength) {
    if (value.length <= maxLength) {
      return true;
    }
    return false;
  }

  verifyNumber(value) {
    var numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) || value === '') {
      return true;
    }
    return false;
  }

  validate(value, validateTypes, maxLength) {
    const result = [];
    for (let i = 0; i < validateTypes.length; i++) {
      const type = validateTypes[i];

      switch (type) {
        case VALIDATE_TYPES.EMPTY:
          if (!this.verifyEmpty(value)) {
            result.push(VALIDATE_TYPES.EMPTY);
          }
          break;
        case VALIDATE_TYPES.MAX_LENGTH:
          if (!this.verifyMaxLength(value, maxLength)) {
            result.push(VALIDATE_TYPES.MAX_LENGTH);
          }
          break;
        case VALIDATE_TYPES.NUMBER:
          if (!this.verifyNumber(value)) {
            result.push(VALIDATE_TYPES.NUMBER);
          }
          break;
        default:
          break;
      }
    }

    return result;
  }

  getErrorText(validateType, maxLength) {
    let result = '';
    switch (validateType) {
      case VALIDATE_TYPES.EMPTY:
        result = '*กรุณากรอกข้อมูลในช่องนี้';
        break;
      case VALIDATE_TYPES.MAX_LENGTH:
        result = `*ไม่สามารถกรอกเกิน ${maxLength} ตัวอักษร`;
        break;
      case VALIDATE_TYPES.NUMBER:
        result = '*กรุณากรอกเฉพาะ "ตัวเลข" เท่านั้น';
        break;
      default:
        break;
    }
    return result;
  }
}

const validateControl = new ValidateControl();
export default validateControl;
