import React from 'react';
import GridItem from 'components/Grid/GridItem.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CloseIcon from '@material-ui/icons/Close';

class SortImageItem extends React.Component {
  render() {
    const { url, indexId } = this.props.children;
    return (
      <GridItem xs={12} sm={3} {...this.props}>
        <CardHeader
          image
          style={{
            margin: '5px 5px 15px 5px',
          }}>
          <div
            style={{
              position: 'absolute',
              top: '-1px',
              right: '-1px',
              transform: 'translate(50%,-50%)',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: 'solid 3px white',
              background: '#4da6ff',
              textAlign: 'center',
            }}
            onClick={() => {
              this.props.onRemoveItem(indexId);
            }}>
            <CloseIcon />
          </div>
          <img src={url} alt="" />
        </CardHeader>
        {/* <span>{this.props.children.sequence}</span> */}
      </GridItem>
    );
  }
}

export default SortImageItem;
