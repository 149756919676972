import React from 'react';
import { sortable } from 'react-sortable';
import SortImageItem from './SortImageItem';
import GridContainer from 'components/Grid/GridContainer.jsx';

var SortableItem = sortable(SortImageItem);

class SortImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items,
    };
  }

  onSortItems = items => {
    this.props.onSortItems(items);
  };

  onRemoveItem = indexId => {
    this.props.onRemoveItem(indexId);
  };

  render() {
    const items = this.props.items || [];
    const listItems = items
      .sort((a, b) => a.sequence - b.sequence)
      .map((item, i) => {
        return (
          <SortableItem
            key={i}
            onSortItems={this.onSortItems}
            onRemoveItem={this.onRemoveItem}
            items={items}
            sortId={i}>
            {item}
          </SortableItem>
        );
      });

    return <GridContainer className="sortable-list">{listItems}</GridContainer>;
  }
}

export default SortImage;
