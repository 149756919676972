import {
  cardTitle,
  dangerColor,
  whiteColor,
  grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const validationFormsStyle = {
  ...customCheckboxRadioSwitch,
  cardTitle: {
    ...cardTitle,
    color: whiteColor
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  formCategory: {
    marginBottom: "0",
    color: grayColor[0],
    fontSize: "14px",
    padding: "10px 0 10px"
  },
  center: {
    textAlign: "center"
  },
  justifyContentCenter: {
    justifyContent: "center"
  },
  registerButton: {
    float: "right"
  },
  danger: {
    color: dangerColor[0] + "!important"
  },
  contentDate: {
    margin: '0 0 17px 0',
    position: 'relative',
    paddingTop: '27px',
    verticalAlign: 'unset'
  },
  labelHorizontalLeft: {
    float: 'left'
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  textDate: {
    border: '0',
    backgroundImage: 'linear-gradient(#9c27b0, #9c27b0), linear-gradient(#d2d2d2, #d2d2d2)',
    backgroundSize: '0 2px, 100% 1px',
    backgroundRepeat:' no-repeat',
    backgroundPosition: 'center bottom, center calc(100% - 1px)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    transition: 'background 0s ease-out',
    float: 'none',
    boxShadow: 'none',
    borderRadius: '0',
    fontWeight: '400',
    width: '100%',
    height: '36px',
    padding: '7px 0',
    fontSize: '12px',
    lineHeight: '1.428571429',
    display: 'block',
    color: '#555'
  },
  labelRootError: {
    color: dangerColor[0] + " !important"
  },
};

export default validationFormsStyle;
