const config = {
  api: {
    url: 'https://api.justcode.club/v1',
  },
  cms: {
    url: 'https://cms.justcode.club',
  },
  demo_url: 'https://my-json-server.typicode.com/typicode/demo',
};

export default config;
