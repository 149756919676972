const config = {
  api: {
    // url: 'http://18.139.228.176/v1',
    url: 'http://localhost:3002/v1',
  },
  cms: {
    url: 'http://localhost:7777',
  },
  demo_url: 'https://my-json-server.typicode.com/typicode/demo',
};

export default config;
