import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import withStyles from '@material-ui/core/styles/withStyles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';

// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from 'react-datetime';
import moment from 'moment';

// material ui icons
import CloseIcon from '@material-ui/icons/Close';
import AvTimer from '@material-ui/icons/AvTimer';
import Person from '@material-ui/icons/Person';
import AccountPerson from '@material-ui/icons/AccountCircle';
import People from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import HouseIcon from '@material-ui/icons/HomeOutlined';
import AppsIcon from '@material-ui/icons/Apps';
import PublicIcon from '@material-ui/icons/Public';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import EditIcon from '@material-ui/icons/Edit';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardText from 'components/Card/CardText.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Tooltip, Checkbox, FormHelperText } from '@material-ui/core';
import Pagination from 'components/Pagination/Pagination.jsx';

// style for this view
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';

import EnCKEditor from '../../components/EnCKEditor';
import Swal from 'sweetalert2';
import DropZone from '../../components/DropZone';
import config from 'config';
import SortImage from './component/SortImage';
import SortIcon from './component/SortIcon';

import ReactTable from 'react-table';
import TagsInput from 'react-tagsinput';
import Modal from 'react-responsive-modal';
import auth from 'utils/auth';

// Util
import validateControl, { VALIDATE_TYPES } from '../../utils/validateControl';
import ModalHouseIcon from './component/ModalHouseIcon';

const dropZoneOption = {
  acceptFile: '.jpg,.png,.gif,.jpeg',
  maxSize: 10,
  limitationText:
    'สามารถอัพโหลดไฟล์นามสกุล (jpg, png, gif) ครั้งละ 1 ไฟล์และขนาดไม่เกิน 10 MB',
};

const defaultState = 'success';
class House extends Component {
  initHouseState = {
    ownerUserId: '',
    name: '',
    shortDetail: defaultState,
    // longDetail: '',
    // termAndCondition: '',
    // nearlyRestaurant: '',
    // nearlyLandmark: '',
    checkIn: defaultState,
    checkOut: defaultState,
    minPerson: '',
    maxPerson: '',
    normalPerson: '',
    // rankScore: '',
    insurancePrice: defaultState,
    kidPrice: defaultState,
    category: '',
    status: defaultState,
  };

  defaultHouseState = {
    ownerUserId: defaultState,
    name: defaultState,
    shortDetail: defaultState,
    // longDetail: defaultState,
    // termAndCondition: defaultState,
    // nearlyRestaurant: defaultState,
    // nearlyLandmark: defaultState,
    checkIn: defaultState,
    checkOut: defaultState,
    minPerson: defaultState,
    maxPerson: defaultState,
    normalPerson: defaultState,
    // rankScore: defaultState,
    insurancePrice: defaultState,
    category: defaultState,
    status: defaultState,
  };

  constructor(props) {
    super(props);

    this.state = {
      houseState: this.initHouseState,
      locationNameState: defaultState,
      isHouseImagesUploading: false,
      isHouseDetailIconsUploading: false,
      locationModalOpen: false,
      houseHighlightIconsModalOpen: false,
      houseFacilityIconsModalOpen: false,
      houseDetailIconsModalOpen: false,
    };
  }

  componentDidMount = async () => {
    try {
      await this.props.user.loadUser();
      await this.props.locations.fetchLocations();
      await this.props.icon.fetchIcons();

      const id = this.props.match.params.id;
      if (id) {
        this.setState({
          houseState: this.defaultHouseState,
        });
        await this.props.house.fetchHouse(id);
      } else {
        this.props.house.resetHouse();
      }
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: error.message,
      });
    }
  };

  ///////////// render

  renderOwnerMenuItem = () => {
    const { classes } = this.props;
    const users = this.props.user.getUsersJS();

    return users.map((user, i) => {
      return (
        <MenuItem
          key={i}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={user.id}>
          {`${user.firstname} ${user.lastname}`}
        </MenuItem>
      );
    });
  };

  renderStatusItem = () => {
    const { classes } = this.props;
    let status = ['CREATED'];
    if (auth.getUserRole() === 'super_admin') {
      status.push('BAN');
      status.push('PUBLISH');
      status.push('DELETE');
    }

    return status.map((status, i) => {
      return (
        <MenuItem
          key={i}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={status}>
          {status}
        </MenuItem>
      );
    });
  };

  renderCategoryItem = () => {
    const { classes } = this.props;
    const categorys = [
      'บ้านพูลวิลล่าสำหรับ 8-15 ท่าน',
      'บ้านพูลวิลล่าสำหรับ 16-25 ท่าน',
      'บ้านพูลวิลล่าสำหรับ 25+ ท่าน',
      'บ้านพักตากอากาศ',
      'บ้านพักสำหรับจัดสัมนา',
    ];

    return categorys.map((category, i) => {
      return (
        <MenuItem
          key={i}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={category}>
          {category}
        </MenuItem>
      );
    });
  };

  ///////////// Validate

  validationForm = () => {
    const currentHouseState = this.state.houseState;
    let statusValidation = true;

    for (const key in currentHouseState) {
      if (currentHouseState.hasOwnProperty(key)) {
        if (
          currentHouseState[key] === '' ||
          currentHouseState[key] !== 'success'
        ) {
          currentHouseState[key] = validateControl.getErrorText(
            VALIDATE_TYPES.EMPTY,
          );
          statusValidation = false;
        }
      }
    }

    this.setState({ houseState: currentHouseState });

    return statusValidation;
  };

  ///////////// Text Event

  onTextFieldChange = (value, stateName, types, maxLength) => {
    const currentHouseState = this.state.houseState;
    const result = validateControl.validate(value, types, maxLength);

    if (result.length > 0) {
      currentHouseState[stateName] = validateControl.getErrorText(
        result[0],
        maxLength,
      );
    } else {
      currentHouseState[stateName] = 'success';
    }

    this.props.house.setHouseProp(stateName, value);

    this.setState({
      houseState: currentHouseState,
    });
  };

  onEditorChange = (event, editor, key) => {
    this.props.house.setHouseProp(key, editor.getData());
  };

  onLocationChange = (event, key) => {
    let validate;
    if (validateControl.verifyEmpty(event.target.value)) {
      validate = 'success';
    } else {
      validate = validateControl.getErrorText(VALIDATE_TYPES.EMPTY);
    }

    this.props.locations.setLocationProp(key, event.target.value);

    this.setState({
      locationNameState: validate,
    });
  };

  ///////////// House Image Event

  onHouseImagesUpload = () => {
    this.setState({ isHouseImagesUploading: true }, () => {
      this.houseImagesDropZone.upload();
    });
  };

  onHouseImagesUploadComplete = async files => {
    const house = this.props.house.getHouseJS();

    const url = files[0].res.url;

    if (url !== undefined && url !== null && url !== '') {
      const houseImage = {
        indexId: house.houseImages.length,
        url: url,
        status: 'ADD',
        sequence: house.houseImages.length + 1,
        isHighlight: house.houseImages.length === 0,
      };

      house.houseImages.push(houseImage);

      this.props.house.setHouseProp('houseImages', house.houseImages);

      this.setState({
        isHouseImagesUploading: false,
      });
    }

    // alert(url);
  };

  onHouseImagesUploadTest = () => {
    const items = [
      'https://images.unsplash.com/photo-1499084732479-de2c02d45fcc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2389&q=80',
      'https://images.unsplash.com/photo-1473016710036-1fe01c8f9b78?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2224&q=80',
      'https://images.unsplash.com/photo-1465070845512-2b2dbdc6df66?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80',
      'https://images.unsplash.com/photo-1413847394921-b259543f4872?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80',
      'https://images.unsplash.com/photo-1475359476683-cb0be9a3bcfc?ixlib=rb-1.2.1&auto=format&fit=crop&w=2100&q=80',
      'https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2293&q=80',
      'https://images.unsplash.com/photo-1444703686981-a3abbc4d4fe3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80',
      'https://images.unsplash.com/photo-1471940393269-df205607fb2a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80',
    ];

    const house = this.props.house.getHouseJS();

    const houseImage = {
      indexId: house.houseImages.length,
      url: items[house.houseImages.length],
      status: 'ADD',
      sequence: house.houseImages.length + 1,
    };

    house.houseImages.push(houseImage);

    this.props.house.setHouseProp('houseImages', house.houseImages);
  };

  ////////////// House Facility Icon Event

  onHouseIconSelect = (type, selectedItems) => {
    this.setState({ [`house${type}IconsModalOpen`]: false });
    this.props.house.setHouseProp(`house${type}Icons`, selectedItems);
  };

  onHouseIconDescChange = (event, indexId, key) => {
    const house = this.props.house.getHouseJS();
    const currentIcon = house[key].find(f => f.indexId === indexId);
    currentIcon.description = event.target.value;
    this.props.house.setHouseProp(key, house[key]);
  };

  ////////////// ImageUpload Event

  onImageUploadRemove = (indexId, key) => {
    const house = this.props.house.getHouseJS();

    const image = house[key].find(h => h.indexId === indexId);
    image.status = 'DELETE';

    this.onImageUploadSort(house[key], key);
  };

  onImageUploadSort = (items, key) => {
    const newItems = items.filter(
      h => h.indexId !== undefined && h.indexId !== '',
    );
    const itemsDelete = newItems
      .filter(h => h.status === 'DELETE')
      .map(h => {
        h.sequence = 0;
        return h;
      });

    const itemsOther = newItems
      .filter(h => h.status !== 'DELETE')
      .map((h, i) => {
        h.isHighlight = i === 0;
        h.sequence = i + 1;
        return h;
      });

    this.props.house.setHouseProp(key, [...itemsOther, ...itemsDelete]);
  };

  ////////////// Location Event

  onSelectTag = item => {
    this.addLocationTag(item.target.value);
  };

  onAddTag = async (allItems, addItems, position) => {
    const tagName = addItems[0];
    const locations = this.props.locations.getLocationsJS();
    const location = locations.find(f => f.name === tagName);

    const addNew = allItems.find(f => f === tagName);

    if (addNew) {
      // Add
      if (!location) {
        this.props.locations.setLocation({
          name: tagName,
        });
        await this.props.locations.saveLocation();
        await this.props.locations.fetchLocations();
      }

      this.addLocationTag(tagName);
    } else {
      // Delete
      this.addLocationTag(tagName);
    }
  };

  addLocationTag = name => {
    const location = this.props.locations.getLocationJSByName(name);
    const house = this.props.house.getHouseJS();
    const houseLocations = house.locations ? house.locations : [];
    const hl = houseLocations.find(f => f.name === location.name);

    if (hl) {
      if (hl.createdBy) {
        hl.status = hl.status === 'DELETE' ? 'UPDATE' : 'DELETE';
        this.props.house.setHouseProp('locations', houseLocations);
      } else {
        const newHL = houseLocations.filter(f => f.name !== hl.name);
        this.props.house.setHouseProp('locations', newHL);
      }
    } else {
      houseLocations.push({
        id: location.id,
        name: location.name,
        status: 'ADD',
      });

      this.props.house.setHouseProp('locations', houseLocations);
    }
  };

  onDeleteLocation = (id, name) => {
    Swal.fire({
      title: 'ยืนยันการลบสถานที่',
      text: `คุณแน่ใจว่าต้องการลบสถานที่ "${name}" นี้?`,
      showCancelButton: true,
      confirmButtonColor: '#9c27b0',
      // cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่, ฉันต้องการลบ',
      cancelButtonText: 'ไม่ใช่',
    }).then(async result => {
      if (result.value) {
        const result = await this.props.locations.deleteLocation(id);

        if (result && result.status === 'success') {
          Swal.fire(
            'ลบสำเร็จ!',
            `คุณได้ทำการลบ "${name}" เรียบร้อย`,
            'success',
          );
        } else {
          let msg =
            'ไม่สามารถทำการลบได้ เนื่องจากยังมีบ้านพักใช้สถานที่นี้อยู่';

          for (let i = 0; i < result.houses.length; i++) {
            const house = result.houses[i];
            msg += '<br />';
            msg += `"${house.name}"`;
          }

          Swal.fire('ลบไม่สำเร็จ', msg, 'warning');
        }
      }
    });
  };

  ////////////// Location Modal Event

  onLocationModalOpen = locationId => {
    this.setState({ locationModalOpen: true });
    const location = this.props.locations.getLocationJSById(locationId);
    this.props.locations.setLocation(location);
  };

  onLocationModalClose = () => {
    this.setState({
      locationModalOpen: false,
      locationNameState: defaultState,
    });
  };

  ////////////// Submit

  saveLocation = async () => {
    const location = this.props.locations.getLocationJS();

    if (validateControl.verifyEmpty(location.name)) {
      await this.props.locations.saveLocation();
      await this.props.locations.fetchLocations();
      this.onLocationModalClose();
    }
  };

  submitClick = async () => {
    try {
      if (this.validationForm()) {
        // if (this.dropZone && this.dropZone.state.files.length > 0) {
        //   this.onUpload();
        // } else {
        //   await this.updateUser();
        // }
        const result = await this.props.house.saveHouse();

        if (result) {
          Swal.fire({
            type: 'success',
            title: 'บันทึก',
            text: 'การบันทึกข้อมูลสำเร็จ',
          }).then(r => {
            if (r.value) {
              this.props.history.push(`/admin/house/${result.id}/edit`);
            }
          });
        } else {
          Swal.fire({
            type: 'error',
            title: 'บันทึก',
            text: 'การบันทึกข้อมูลไม่สำเร็จ',
          });
        }
      }
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: 'บันทึก',
        text: 'บันทึกไม่สำเร็จ ระบบผิดพลาด',
      });
    }
  };

  renderAllLocation = () => {
    const house = this.props.house.getHouseJS();
    const houseLocations = house.locations ? house.locations : [];
    const { classes } = this.props;

    const locations = this.props.locations.getLocationsJS().map(item => {
      const hl = houseLocations.find(f => f.name === item.name);

      if (hl) {
        return {
          isSelect: hl.status && hl.status !== 'DELETE' ? true : false,
          id: item.id,
          name: item.name,
        };
      } else {
        return {
          isSelect: false,
          id: item.id,
          name: item.name,
        };
      }
    });

    const locationsHTML = locations.map(l => {
      let action;
      if (l.isSelect === false) {
        action = (
          <div className="actions-center">
            <Tooltip title="แก้ไข" placement="top">
              <Button
                justIcon
                round
                onClick={() => this.onLocationModalOpen(l.id)}
                color="warning"
                className="edit">
                <EditIcon />
              </Button>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;
            <Tooltip title="ลบ" placement="top">
              <Button
                justIcon
                round
                onClick={() => this.onDeleteLocation(l.id, l.name)}
                color="danger"
                className="remove">
                <CloseIcon />
              </Button>
            </Tooltip>
          </div>
        );
      }

      return {
        select: (
          <Checkbox
            checked={l.isSelect}
            value={l.name}
            tabIndex={-1}
            onClick={this.onSelectTag}
            checkedIcon={<CheckIcon className={classes.checkedIcon} />}
            icon={<CheckIcon className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        ),
        name: l.name,
        actions: action,
      };
    });

    return (
      <ReactTable
        data={locationsHTML}
        filterable
        columns={[
          {
            Header: 'เลือก',
            accessor: 'select',
            width: 80,
          },
          {
            Header: 'ชื่อสถานที่',
            accessor: 'name',
          },
          {
            Header: '',
            headerStyle: { textAlign: 'center' },
            accessor: 'actions',
            sortable: false,
            filterable: false,
          },
        ]}
        defaultPageSize={10}
        showPaginationTop={false}
        showPaginationBottom={true}
        className="-striped -highlight"
      />
    );
  };

  renderRankScore = rankScore => {
    const ranks = [];
    for (let i = 1; i < 6; i++) {
      ranks.push({
        text: i,
        active: rankScore === i,
        onClick: () => {
          this.props.house.setHouseProp('rankScore', i);
        },
      });
    }

    return (
      <Pagination
        ulStyle={{
          margin: '7px 0px 0px 0px',
        }}
        pages={ranks}
        color="info"
      />
    );
  };

  render() {
    const { classes } = this.props;
    const { mode } = this.props.house;
    const house = this.props.house.getHouseJS();
    const location = this.props.locations.getLocationJS();
    const icons = this.props.icon.getIconsJS();
    const {
      houseState,
      locationNameState,
      locationModalOpen,
      houseHighlightIconsModalOpen,
      houseFacilityIconsModalOpen,
      houseDetailIconsModalOpen,
    } = this.state;

    const houseImages = house.houseImages
      ? house.houseImages.filter(h => h.status !== 'DELETE')
      : [];
    const houseHighlightIcons = house.houseHighlightIcons
      ? house.houseHighlightIcons.filter(h => h.status !== 'DELETE')
      : [];
    const houseFacilityIcons = house.houseFacilityIcons
      ? house.houseFacilityIcons.filter(h => h.status !== 'DELETE')
      : [];
    const houseDetailIcons = house.houseDetailIcons
      ? house.houseDetailIcons.filter(h => h.status !== 'DELETE')
      : [];
    const houseLocations = house.locations
      ? house.locations.filter(h => h.status !== 'DELETE').map(h => h.name)
      : [];

    return (
      <React.Fragment>
        {/* Name, Owner, Status */}
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <HomeIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>ชื่อบ้านพัก</h4>
              </CardHeader>
              <CardBody>
                <FormControl fullWidth>
                  <CustomInput
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    success={houseState.name === 'success'}
                    error={houseState.name !== 'success'}
                    helperText={
                      houseState.name === 'success' ? '' : houseState.name
                    }
                    inputProps={{
                      onChange: event =>
                        this.onTextFieldChange(
                          event.target.value,
                          'name',
                          [VALIDATE_TYPES.EMPTY, VALIDATE_TYPES.MAX_LENGTH],
                          90,
                        ),
                      type: 'text',
                      value: `${house.name || ''}`,
                      name: 'name',
                      endAdornment:
                        houseState.name === 'success' ||
                        houseState.name === '' ? (
                          undefined
                        ) : (
                          <InputAdornment position="end">
                            <CloseIcon className={classes.danger} />
                          </InputAdornment>
                        ),
                    }}
                  />
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={3}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <AccountPerson />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>เจ้าของบ้าน</h4>
              </CardHeader>
              <CardBody>
                <br />
                <FormControl
                  fullWidth
                  // margin="10"
                  className={classes.selectFormControl}>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={house.ownerUserId}
                    onChange={event =>
                      this.onTextFieldChange(
                        event.target.value,
                        'ownerUserId',
                        [VALIDATE_TYPES.EMPTY],
                      )
                    }>
                    {this.renderOwnerMenuItem()}
                  </Select>
                  <FormHelperText error={true}>
                    {houseState.ownerUserId === 'success'
                      ? ''
                      : houseState.ownerUserId}
                  </FormHelperText>
                  <br />
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={3}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <PublicIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>สถานะบ้านพัก</h4>
              </CardHeader>
              <CardBody>
                <br />
                <FormControl
                  fullWidth
                  // margin="10"
                  className={classes.selectFormControl}>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={house.status}
                    onChange={event =>
                      this.onTextFieldChange(event.target.value, 'status', [
                        VALIDATE_TYPES.EMPTY,
                      ])
                    }>
                    {this.renderStatusItem()}
                  </Select>
                  <FormHelperText error={true}>
                    {houseState.status === 'success' ? '' : houseState.status}
                  </FormHelperText>
                  <br />
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {/* CheckIn, CheckOut, MinPerson, MaxPerson */}
        <GridContainer>
          <GridItem xs={12} sm={3}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <AvTimer />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>เวลาเช็คอิน</h4>
              </CardHeader>
              <CardBody>
                <FormControl fullWidth>
                  <br />
                  <Datetime
                    dateFormat={false}
                    timeFormat={'HH:mm'}
                    // defaultValue={new Date(2011, 0, 1, 14, 0, 0, 0)}
                    inputProps={{ placeholder: 'Check In Here' }}
                    value={moment(house.checkIn, 'HH:mm') || ''}
                    onChange={value =>
                      this.onTextFieldChange(value, 'checkIn', [])
                    }
                  />
                  <br />
                  <br />
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={3}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <AvTimer />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>เวลาเช็คเอ้าท์</h4>
              </CardHeader>
              <CardBody>
                <FormControl fullWidth>
                  <br />
                  <Datetime
                    dateFormat={false}
                    timeFormat={'HH:mm'}
                    // defaultValue={new Date(2011, 0, 1, 12, 0, 0, 0)}
                    success={houseState.checkOut === 'success'}
                    error={houseState.checkOut === 'error'}
                    inputProps={{ placeholder: 'Check Out Here' }}
                    value={moment(house.checkOut, 'HH:mm') || ''}
                    onChange={value =>
                      this.onTextFieldChange(value, 'checkOut', [])
                    }
                  />
                  <br />
                  <br />
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={2}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Person />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>จำนวน</h4>
                <div style={{ textAlign: 'center' }}>
                  <h4 className={classes.cardIconTitle}>
                    ผู้เข้าพัก "ขั้นต่ำ"
                  </h4>
                </div>
              </CardHeader>
              <CardBody>
                <FormControl fullWidth>
                  <CustomInput
                    id="minPerson"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    success={houseState.minPerson === 'success'}
                    error={houseState.minPerson !== 'success'}
                    helperText={
                      houseState.minPerson === 'success'
                        ? ''
                        : houseState.minPerson
                    }
                    inputProps={{
                      onChange: event =>
                        this.onTextFieldChange(
                          event.target.value,
                          'minPerson',
                          [
                            VALIDATE_TYPES.EMPTY,
                            VALIDATE_TYPES.MAX_LENGTH,
                            VALIDATE_TYPES.NUMBER,
                          ],
                          3,
                        ),
                      type: 'text',
                      value: `${house.minPerson || ''}`,
                      name: 'minPerson',
                      endAdornment:
                        houseState.minPerson === 'success' ||
                        houseState.minPerson === '' ? (
                          undefined
                        ) : (
                          <InputAdornment position="end">
                            <CloseIcon className={classes.danger} />
                          </InputAdornment>
                        ),
                    }}
                  />
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={2}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Person />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>จำนวน</h4>
                <div style={{ textAlign: 'center' }}>
                  <h4 className={classes.cardIconTitle}>ผู้เข้าพัก "ปกติ"</h4>
                </div>
              </CardHeader>
              <CardBody>
                <FormControl fullWidth>
                  <CustomInput
                    id="normalPerson"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    success={houseState.normalPerson === 'success'}
                    error={houseState.normalPerson !== 'success'}
                    helperText={
                      houseState.normalPerson === 'success'
                        ? ''
                        : houseState.normalPerson
                    }
                    inputProps={{
                      onChange: event =>
                        this.onTextFieldChange(
                          event.target.value,
                          'normalPerson',
                          [
                            VALIDATE_TYPES.EMPTY,
                            VALIDATE_TYPES.MAX_LENGTH,
                            VALIDATE_TYPES.NUMBER,
                          ],
                          3,
                        ),
                      type: 'text',
                      value: `${house.normalPerson || ''}`,
                      name: 'normalPerson',
                      endAdornment:
                        houseState.normalPerson === 'success' ||
                        houseState.normalPerson === '' ? (
                          undefined
                        ) : (
                          <InputAdornment position="end">
                            <CloseIcon className={classes.danger} />
                          </InputAdornment>
                        ),
                    }}
                  />
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={2}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <People />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>จำนวน</h4>
                <div style={{ textAlign: 'center' }}>
                  <h4 className={classes.cardIconTitle}>
                    ผู้เข้าพัก "ห้ามเกิน"
                  </h4>
                </div>
              </CardHeader>
              <CardBody>
                <FormControl fullWidth>
                  <CustomInput
                    id="maxPerson"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    success={houseState.maxPerson === 'success'}
                    error={houseState.maxPerson !== 'success'}
                    helperText={
                      houseState.maxPerson === 'success'
                        ? ''
                        : houseState.maxPerson
                    }
                    inputProps={{
                      onChange: event =>
                        this.onTextFieldChange(
                          event.target.value,
                          'maxPerson',
                          [
                            VALIDATE_TYPES.EMPTY,
                            VALIDATE_TYPES.MAX_LENGTH,
                            VALIDATE_TYPES.NUMBER,
                          ],
                          3,
                        ),
                      type: 'text',
                      value: `${house.maxPerson || ''}`,
                      name: 'maxPerson',
                      endAdornment:
                        houseState.maxPerson === 'success' ||
                        houseState.maxPerson === '' ? (
                          undefined
                        ) : (
                          <InputAdornment position="end">
                            <CloseIcon className={classes.danger} />
                          </InputAdornment>
                        ),
                    }}
                  />
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {/* Location */}
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <LocationCityIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  ชื่อสถานที่ใช้ในการค้นหา
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={4}>
                    <h4 className={classes.cardIconTitle}>สถานที่ Tag</h4>

                    <TagsInput
                      value={houseLocations}
                      placeholder="เพิ่มสถานที่"
                      onChange={this.onAddTag}
                      tagProps={{ className: 'react-tagsinput-tag info' }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <h4 className={classes.cardIconTitle}>
                      สถานที่ที่มีอยู่ทั้งหมด
                    </h4>

                    {this.renderAllLocation()}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {/* House Image */}
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <HouseIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>รูปบ้าน</h4>
              </CardHeader>
              <CardBody>
                <FormControl fullWidth>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <DropZone
                        ref={e => {
                          this.houseImagesDropZone = e;
                        }}
                        placeholder="Click here to upload a photo."
                        url={`${config.api.url}/uploads`}
                        acceptedFiles={dropZoneOption.acceptFile}
                        maxFileSize={dropZoneOption.maxSize}
                        dirName="house_images"
                        // fileName=""
                        onAllComplete={this.onHouseImagesUploadComplete}
                      />
                      <Button
                        fullWidth
                        color="primary"
                        onClick={this.onHouseImagesUpload}>
                        อัพโหลดรูปภาพ
                      </Button>
                      {/* <Button
                        fullWidth
                        color="primary"
                        onClick={this.onHouseImagesUploadTest}>
                        Upload Test
                      </Button> */}
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <SortImage
                        items={houseImages}
                        onSortItems={items =>
                          this.onImageUploadSort(items, 'houseImages')
                        }
                        onRemoveItem={indexId =>
                          this.onImageUploadRemove(indexId, 'houseImages')
                        }
                      />
                    </GridItem>
                  </GridContainer>
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {/* House Highlight Image */}
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <AppsIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>ไอคอนไฮไลท์</h4>
              </CardHeader>
              <CardBody>
                <FormControl fullWidth>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <Button
                        fullWidth
                        color="primary"
                        onClick={() =>
                          this.setState({ houseHighlightIconsModalOpen: true })
                        }>
                        <AddIcon /> เพิ่มไอคอน
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <SortIcon
                        items={houseHighlightIcons}
                        onSortItems={items =>
                          this.onImageUploadSort(items, 'houseHighlightIcons')
                        }
                        onRemoveItem={indexId =>
                          this.onImageUploadRemove(
                            indexId,
                            'houseHighlightIcons',
                          )
                        }
                        onDescChange={(event, indexId) =>
                          this.onHouseIconDescChange(
                            event,
                            indexId,
                            'houseHighlightIcons',
                          )
                        }
                      />
                    </GridItem>
                  </GridContainer>
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {/* House Facility Image */}
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <AppsIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  ไอคอนสิ่งอำนวยความสะดวก
                </h4>
              </CardHeader>
              <CardBody>
                <FormControl fullWidth>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <Button
                        fullWidth
                        color="primary"
                        onClick={() =>
                          this.setState({ houseFacilityIconsModalOpen: true })
                        }>
                        <AddIcon /> เพิ่มไอคอน
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <SortIcon
                        items={houseFacilityIcons}
                        onSortItems={items =>
                          this.onImageUploadSort(items, 'houseFacilityIcons')
                        }
                        onRemoveItem={indexId =>
                          this.onImageUploadRemove(
                            indexId,
                            'houseFacilityIcons',
                          )
                        }
                        onDescChange={(event, indexId) =>
                          this.onHouseIconDescChange(
                            event,
                            indexId,
                            'houseFacilityIcons',
                          )
                        }
                      />
                    </GridItem>
                  </GridContainer>
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {/* House Detail Image */}
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <AppsIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  ไอคอนรายละเอียดบ้านพัก
                </h4>
              </CardHeader>
              <CardBody>
                <FormControl fullWidth>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <Button
                        fullWidth
                        color="primary"
                        onClick={() =>
                          this.setState({ houseDetailIconsModalOpen: true })
                        }>
                        <AddIcon /> เพิ่มไอคอน
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <SortIcon
                        items={houseDetailIcons}
                        onSortItems={items =>
                          this.onImageUploadSort(items, 'houseDetailIcons')
                        }
                        onRemoveItem={indexId =>
                          this.onImageUploadRemove(indexId, 'houseDetailIcons')
                        }
                        onDescChange={(event, indexId) =>
                          this.onHouseIconDescChange(
                            event,
                            indexId,
                            'houseDetailIcons',
                          )
                        }
                      />
                    </GridItem>
                  </GridContainer>
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {/* Create House */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary" text>
                <CardText color="primary">
                  <h4 className={classes.cardTitle}>ข้อมูลบ้านพัก</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        {/* Insurance Price */}
                        เงินประกัน
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <CustomInput
                        id="insurancePrice"
                        success={houseState.insurancePrice === 'success'}
                        error={houseState.insurancePrice !== 'success'}
                        helperText={
                          houseState.insurancePrice === 'success'
                            ? ''
                            : houseState.insurancePrice
                        }
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: event =>
                            this.onTextFieldChange(
                              event.target.value,
                              'insurancePrice',
                              [
                                VALIDATE_TYPES.MAX_LENGTH,
                                VALIDATE_TYPES.NUMBER,
                              ],
                              6,
                            ),
                          type: 'text',
                          value: `${house.insurancePrice || ''}`,
                          name: 'insurancePrice',
                          endAdornment:
                            houseState.insurancePrice === 'success' ||
                            houseState.insurancePrice === '' ? (
                              undefined
                            ) : (
                              <InputAdornment position="end">
                                <CloseIcon className={classes.danger} />
                              </InputAdornment>
                            ),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        ราคาเด็กอายุไม่เกิน 11 ขวบ
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <CustomInput
                        id="kidPrice"
                        success={houseState.kidPrice === 'success'}
                        error={houseState.kidPrice !== 'success'}
                        helperText={
                          houseState.kidPrice === 'success'
                            ? ''
                            : houseState.kidPrice
                        }
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: event =>
                            this.onTextFieldChange(
                              event.target.value,
                              'kidPrice',
                              [
                                VALIDATE_TYPES.MAX_LENGTH,
                                VALIDATE_TYPES.NUMBER,
                              ],
                              6,
                            ),
                          type: 'text',
                          value: `${house.kidPrice || ''}`,
                          name: 'kidPrice',
                          endAdornment:
                            houseState.kidPrice === 'success' ||
                            houseState.kidPrice === '' ? (
                              undefined
                            ) : (
                              <InputAdornment position="end">
                                <CloseIcon className={classes.danger} />
                              </InputAdornment>
                            ),
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  {/* Category */}
                  <GridContainer>
                    <GridItem xs={12} md={2}>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{
                          paddingTop: '12px',
                        }}>
                        ประเภทบ้านพัก
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} md={4}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={house.category}
                          onChange={event =>
                            this.onTextFieldChange(
                              event.target.value,
                              'category',
                              [VALIDATE_TYPES.EMPTY],
                            )
                          }>
                          {this.renderCategoryItem()}
                        </Select>
                        <FormHelperText error={true}>
                          {houseState.category === 'success'
                            ? ''
                            : houseState.category}
                        </FormHelperText>
                        <br />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{
                          paddingTop: '12px',
                        }}>
                        ลำดับในการค้นหา
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      {this.renderRankScore(house.rankScore)}
                    </GridItem>
                  </GridContainer>

                  {/* Short Detail */}
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        ข้อมูลบ้านพักไฮไลท์
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <EnCKEditor
                        data={house.shortDetail || ''}
                        onChange={(event, editor) =>
                          this.onEditorChange(event, editor, 'shortDetail')
                        }
                      />
                      <br />
                    </GridItem>
                  </GridContainer>

                  {/* Long Detail */}
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        รายละเอียดเพิ่มเติม
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <EnCKEditor
                        data={house.longDetail || ''}
                        onChange={(event, editor) =>
                          this.onEditorChange(event, editor, 'longDetail')
                        }
                      />
                      <br />
                    </GridItem>
                  </GridContainer>

                  {/* Term And Condition */}
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        กฎระเบียบบ้านพัก
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <EnCKEditor
                        data={house.termAndCondition || ''}
                        onChange={(event, editor) =>
                          this.onEditorChange(event, editor, 'termAndCondition')
                        }
                      />
                      <br />
                    </GridItem>
                  </GridContainer>

                  {/* Nearly Restaurant */}
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        ร้านอาหารและคาเฟ่ใกล้เคียง
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <EnCKEditor
                        data={house.nearlyRestaurant || ''}
                        onChange={(event, editor) =>
                          this.onEditorChange(event, editor, 'nearlyRestaurant')
                        }
                      />
                      <br />
                    </GridItem>
                  </GridContainer>

                  {/* Nearly Landmark */}
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        แหล่งท่องเที่ยวใกล้เคียง
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <EnCKEditor
                        data={house.nearlyLandmark || ''}
                        onChange={(event, editor) =>
                          this.onEditorChange(event, editor, 'nearlyLandmark')
                        }
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="primary" onClick={this.submitClick}>
                  {mode === 'edit' ? 'แก้ไข' : 'บันทึก'}
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>

        <ModalHouseIcon
          isOpenModal={houseHighlightIconsModalOpen}
          icons={icons || []}
          iconsSelect={houseHighlightIcons}
          onHouseIconSelect={selectedItems =>
            this.onHouseIconSelect('Highlight', selectedItems)
          }
          onCloseModal={() =>
            this.setState({ houseHighlightIconsModalOpen: false })
          }
        />

        <ModalHouseIcon
          isOpenModal={houseFacilityIconsModalOpen}
          icons={icons || []}
          iconsSelect={houseFacilityIcons}
          onHouseIconSelect={selectedItems =>
            this.onHouseIconSelect('Facility', selectedItems)
          }
          onCloseModal={() =>
            this.setState({ houseFacilityIconsModalOpen: false })
          }
        />

        <ModalHouseIcon
          isOpenModal={houseDetailIconsModalOpen}
          icons={icons || []}
          iconsSelect={houseDetailIcons}
          onHouseIconSelect={selectedItems =>
            this.onHouseIconSelect('Detail', selectedItems)
          }
          onCloseModal={() =>
            this.setState({ houseDetailIconsModalOpen: false })
          }
        />

        <Modal
          open={locationModalOpen}
          onClose={this.onLocationModalClose}
          center>
          <FormControl fullWidth>
            <h2>Location Name</h2>
            <CustomInput
              id="location_name"
              success={locationNameState === 'success'}
              error={locationNameState !== 'success'}
              helperText={
                locationNameState === 'success' ? '' : locationNameState
              }
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: event => this.onLocationChange(event, 'name'),
                type: 'text',
                value: `${location.name || ''}`,
                name: 'location_name',
                endAdornment:
                  locationNameState === 'success' ||
                  locationNameState === '' ? (
                    undefined
                  ) : (
                    <InputAdornment position="end">
                      <CloseIcon className={classes.danger} />
                    </InputAdornment>
                  ),
              }}
            />
            <Button color="primary" onClick={this.saveLocation}>
              Save
            </Button>
          </FormControl>
        </Modal>
      </React.Fragment>
    );
  }
}

House.propTypes = {
  classes: PropTypes.object,
};

export const page = inject('house', 'user', 'locations', 'icon')(
  observer(House),
);
export default withStyles(
  validationFormsStyle,
  sweetAlertStyle,
  extendedFormsStyle,
)(page);
