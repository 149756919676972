/*eslint-disable*/
import React from 'react';
// eslint-disable-next-line
import ReactExport from 'react-data-export';
import PropTypes from 'prop-types';
// import { Button } from '@material-ui/core';
// import SaveIcon from '@material-ui/icons/Save';
import withStyles from '@material-ui/core/styles/withStyles';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExcelButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { fileName, sheetName, columns, data, button } = this.props;

    return (
      <ExcelFile filename={fileName || 'Report'} element={button || 'Export'}>
        <ExcelSheet data={data} name={sheetName || 'Data'}>
          {columns.map(({ label, value }, index) => (
            <ExcelColumn label={label} value={value} />
          ))}
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

const styles = {
  button: {
    backgroundColor: 'rgba(255, 0, 0, 0.0)',
    color: '#fff',
  },
};

ExcelButton.propTypes = {
  classes: PropTypes.object,
  fileName: PropTypes.string,
  sheetName: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  data: PropTypes.array.isRequired,
  button: PropTypes.element,
};

export default withStyles(styles)(ExcelButton);
