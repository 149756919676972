import { extendObservable, toJS } from 'mobx';
import config from 'config';
// import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import httpClient from 'utils/http';

const initialLocation = {
  id: '',
  name: '',
};

export class LocationStore {
  constructor() {
    extendObservable(this, {
      loading: false,
      location: initialLocation,
      locations: [],
    });
  }

  getLocationJS() {
    return toJS(this.location);
  }

  getLocationsJS() {
    return toJS(this.locations);
  }

  getLocationJSById(id) {
    const locations = toJS(this.locations);
    return locations.find(f => f.id === id);
  }

  getLocationJSByName(name) {
    const locations = toJS(this.locations);
    return locations.find(f => f.name === name);
  }

  setLocation(location) {
    this.location = location;
  }

  setLocations(locations) {
    this.locations = locations;
  }

  setLocationProp(key, value) {
    this.location[key] = value;
  }

  async fetchLocations() {
    this.loading = true;
    try {
      const response = await httpClient.get(`/locations`);

      this.setLocations(response || []);
    } catch (error) {
      console.log(error.message);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async saveLocation() {
    try {
      this.loading = true;
      const data = snakecaseKeys(this.location);

      let response;
      let url = `${config.api.url}/locations`;

      if (
        this.location.id !== null &&
        this.location.id !== undefined &&
        this.location.id !== ''
      ) {
        console.log('## Update Location');
        response = await httpClient.put(url + `/${this.location.id}`, data);
      } else {
        console.log('## Insert Location');
        response = await httpClient.post(url, data);
      }

      return response;
    } catch (error) {
      // throw error;
      console.log('## Error when call AddLocation(): ', error);
      throw new Error(error.response.data.code);
    } finally {
      this.loading = false;
    }
  }

  async deleteLocation(id) {
    this.loading = true;
    try {
      const response = await httpClient.delete(`/locations/${id}`);
      if (response && response.status === 'success') {
        this.locations = this.locations.filter(val => val.id !== id);
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }
}

const locationStore = new LocationStore();
export default locationStore;
