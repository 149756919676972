import user from './UserStore';
import booking from './BookingStore';
import house from './HouseStore';
import priceSetting from './PriceSettingStore';
import priceCalendar from './PriceCalendarStore';
import bookingEdit from './BookingEditStore';
import holiday from './HolidayStore';
import locations from './LocationStore';
import icon from './IconStore';
import contact from './ContactStore';
import bankAccount from './BankAccountStore';
import report from './ReportStore';

export default {
  user,
  house,
  booking,
  priceSetting,
  priceCalendar,
  bookingEdit,
  holiday,
  locations,
  icon,
  contact,
  bankAccount,
  report,
};
