import { extendObservable } from 'mobx';
import http from '../utils/http';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

const initialpriceSetting = {
  houseId: 1,
  calendarDate: "2020-01-15T00:00:00.000Z",
  price: 0,
  specialPrice: 0,
  pricePerPerson: 0,
};

export class PriceCalendarStore {
  constructor() {
    extendObservable(this, {
      loading: false,
      priceCalendar: [],
      priceSetting: initialpriceSetting,
      mode: 'create',
    });
  }

  setPriceCalendar(data) {
    let ref = {};
    let res = data.reduce(function(arr1, o) {
      let date = new Date(o.calendar_date);
      if (!(date in ref)) {
        ref[date] = arr1.length;
        arr1.push([]);
      }
      arr1[ref[date]]=o;
      return arr1;
    }, []);
    this.priceCalendar = camelcaseKeys(res);
  }

  changeField(task, data) {
    this.priceSetting[task] = data;
  }

  async updatePriceCalendar(id) {
    this.loading = true;
    const data = snakecaseKeys(this.priceSetting);
    try {
      let url = `/price-calendars/${id}`;
      let priceCalendars = await http.put(url, data);
      if (priceCalendars) {
        let priceSetting = priceCalendars;
        let newpriceCalendar = this.priceCalendar.map(val=>{
          if(val.id === priceSetting.id){
            val = camelcaseKeys(priceSetting);
          }
          return val;
        })
        this.priceCalendar = newpriceCalendar;
      }
      return priceCalendars;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
    
  }

  async loadPriceCalendar(id) {
    this.loading = true;
    try {
      const priceCalendars = await http.get(`/price-calendars?house_id=${id}`);
      if (priceCalendars) {
        this.setPriceCalendar(priceCalendars);
      }
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }
}

const priceCalendarStore = new PriceCalendarStore();
export default priceCalendarStore;
