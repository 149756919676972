import React from 'react';
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import errorPageStyles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.jsx";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      hasError: true,
      error: error,
      errorInfo: info,
    });
    // You can also log error messages to an error reporting service here
    if (error) {
      console.log(error.toString());
    }
    if (info) {
      console.log(info.componentStack);
    }
  }

  render() {
    const { classes } = this.props;
    if (this.state.errorInfo) {
      return (
        <div className={classes.contentCenter}>
          <GridContainer>
            <GridItem md={12}>
              <h2 className={classes.title}>ระบบเกิดข้อผิดพลาด</h2>
              <h3 className={classes.subTitle}>กรุณาแจ้งผู้ดูแลระบบ</h3>
              <h4 className={classes.description} style={{ whiteSpace: 'pre-wrap' }}>
                {this.state.error && this.state.error.toString()}
                {
                  // this.state.errorInfo.componentStack
                }
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(errorPageStyles)(ErrorBoundary);