/*eslint-disable*/
import React from "react";
import { observer, inject } from 'mobx-react';
import PropTypes from "prop-types";
// react component used to create a calendar with events on it
import BigCalendar from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
import FormLabel from '@material-ui/core/FormLabel';
// react component used to create alerts
import SweetAlert from "react-bootstrap-sweetalert";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
// @material-ui/core components
import Add from "@material-ui/icons/Add";
import withStyles from "@material-ui/core/styles/withStyles";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
// core components
import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx';

const styles = {
  ...validationFormsStyle,
  ...buttonStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  }
};

const localizer = BigCalendar.momentLocalizer(moment);

class PriceCalendar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      priceState: '',
      specialPriceState: '',
      pricePerPersonState: '',
      calendarDate: '',
      id: '',
      houseId: '',
      price: '',
      pricePerPerson: '',
      specialPrice: '',
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.cancelDetele = this.cancelDetele.bind(this);
    this.successDelete = this.successDelete.bind(this);

  }
  async componentDidMount() {
    let id = this.props.match.params.id;
    if (id) {
      try {
        await this.props.house.fetchHouse(id);
        await this.props.priceCalendar.loadPriceCalendar(id);
      } catch (error) {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block", marginTop: "-100px" }}
              title={`Error !`}
              onConfirm={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
              {`${error.message}`}
            </SweetAlert>
          )
        });
      }
    }
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    this.props.priceCalendar.changeField(event.target.name, event.target.value);
    switch (type) {
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      default:
        break;
    }
  }

  async confirmUpdatePrice(id) {
    const { priceSetting } = this.props.priceCalendar;
    this.setState({
      alert: (
        <SweetAlert
          warning
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          confirmBtnText="Confirm"
          title="Are you sure?"
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          onConfirm={() => this.updatePrice(id)}
          onCancel={() => this.cancelDetele()}
          focusCancelBtn
        >
          <form>
            <b>Price</b> : {priceSetting.price}<br />
            <b>Special Price</b> : {priceSetting.specialPrice}<br />
            <b>Price Per Person</b> : {priceSetting.pricePerPerson}
          </form>
        </SweetAlert>
      )
    });
  }
  successDelete() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
        </SweetAlert>
      )
    });
  }
  cancelDetele() {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Cancelled"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
        </SweetAlert>
      )
    });
  }

  async updatePrice(id) {
    try {
      const response = await this.props.priceCalendar.updatePriceCalendar(id);
      if (response) {
        this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Success !"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      } else {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block", marginTop: "-100px" }}
              title="Error !"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      }
    } catch (error) {
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block", marginTop: "-100px" }}
            title="Error !"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
            {`${error}`}
          </SweetAlert>
        )
      });
    }

  }


  async selectedEvent(slotInfo) {
    const { classes } = this.props;
    await this.props.priceCalendar.changeField('calendarDate', slotInfo.calendarDate);
    await this.props.priceCalendar.changeField('price', slotInfo.price);
    await this.props.priceCalendar.changeField('specialPrice', slotInfo.specialPrice);
    await this.props.priceCalendar.changeField('pricePerPerson', slotInfo.pricePerPerson);
    const { priceSetting } = this.props.priceCalendar;
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          // onConfirm={e => this.addNewEvent(e, slotInfo)}
          onCancel={() => this.hideAlert()}
          confirmBtnText={'Save'}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          // onConfirm={e => this.confirmUpdatePrice(e, slotInfo)}
          onConfirm={() => this.confirmUpdatePrice(slotInfo.id)}
        >
          <form>
            <h4 className={classes.cardTitle} style={{ color: 'black' }}>
              {'Price Setting'}
            </h4>
            <GridContainer>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.labelHorizontal}>
                  Price
              </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <CustomInput
                  success={this.state.priceState === 'success'}
                  error={this.state.priceState === 'error'}
                  id="price"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event =>
                      this.change(event, 'price', 'length', 1),
                    type: 'number',
                    // value: `${priceSetting.price || ''}`,
                    placeholder: `${priceSetting.price || ''}`,
                    name: 'price',
                    endAdornment:
                      this.state.priceState === 'error' ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                          undefined
                        ),
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.labelHorizontal}>
                  Special Price
              </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <CustomInput
                  success={this.state.specialPriceState === 'success'}
                  error={this.state.specialPriceState === 'error'}
                  id="special-price"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event =>
                      this.change(event, 'specialPrice', 'length', 1),
                    type: 'number',
                    // value: `${slotInfo.specialPrice || ''}`,
                    placeholder: `${priceSetting.specialPrice || ''}`,
                    name: 'specialPrice',
                    endAdornment:
                      this.state.specialPriceState === 'error' ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                          undefined
                        ),
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.labelHorizontal}>
                  Price Per Person
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <CustomInput
                  success={this.state.pricePerPersonState === 'success'}
                  error={this.state.pricePerPersonState === 'error'}
                  id="priceperperson"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event =>
                      this.change(event, 'pricePerPerson', 'length', 1),
                    type: 'number',
                    // value: `${slotInfo.pricePerPerson || ''}`,
                    placeholder: `${priceSetting.pricePerPerson || ''}`,
                    name: 'pricePerPerson',
                    endAdornment:
                      this.state.pricePerPersonState === 'error' ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                          undefined
                        ),
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </SweetAlert>
      )
    });
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  addNewPrice(e) {
    console.log('e', e);
  }

  eventColors(event) {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  }
  render() {
    const { classes } = this.props;
    const { house } = this.props.house;
    let { priceCalendar } = this.props.priceCalendar;
    const { priceSetting } = this.props.priceCalendar;
    let id = this.props.match.params.id;
    priceCalendar.map(val => {
      val.title = `${val.specialPrice} ฿`
      val.allDay = true
      val.start = val.calendarDate
      val.end = val.calendarDate
      val.color = 'orange'
      return val
    })

    return (
      <div>
        <Heading
          textAlign="center"
          title="Price Calendar"
          category={
            <span></span>
          }
        />
        {this.state.alert}
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <MonetizationOn />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{house.name || ''}</h4>
                <CardIcon onClick={() => { this.props.history.push(`/admin/house/price-settings/${id}`) }} color="info" style={{ float: 'right', borderRadius: '35px', cursor: 'pointer', padding: '5px' }}>
                  <Add /> <div style={{ float: 'right', paddingTop: '7px', fontWeight: 'bold', paddingRight: '6px', }}>Create Price Setting</div>
                </CardIcon>
              </CardHeader>
              <CardBody calendar>
                <BigCalendar
                  selectable
                  localizer={localizer}
                  events={priceCalendar}
                  defaultView="month"
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  onSelectEvent={event => this.selectedEvent(event)}
                  eventPropGetter={this.eventColors}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

PriceCalendar.propTypes = {
  classes: PropTypes.object
};

export const page = inject('priceCalendar', 'house')(observer(PriceCalendar));
export default withStyles(styles, { withTheme: true })(page);
