const config = {
  api: {
    url: 'https://api.besthavenpoolvilla.com/v1',
  },
  cms: {
    url: 'https://cms.besthavenpoolvilla.com',
  },
  demo_url: 'https://my-json-server.typicode.com/typicode/demo',
};

export default config;
