import React, { Component } from 'react';
import PropTypes from 'prop-types';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import { observer, inject } from 'mobx-react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Calendar from '@material-ui/icons/CalendarToday';
import Setting from '@material-ui/icons/Settings';

// import Visibility from "@material-ui/icons/Visibility";
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import Home from '@material-ui/icons/Home';
import { Tooltip } from '@material-ui/core';
import Swal from 'sweetalert2';
import SweetAlert from "react-bootstrap-sweetalert";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import auth from 'utils/auth';

const styles = {
  ...buttonStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

class Houses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
    };
    this.hideAlert = this.hideAlert.bind(this);
  }

  async componentDidMount() {
    try {
      if(auth.getUserRole() === 'user'){
        const userInfo = auth.getUserInfo();
        await this.props.house.getHouseByOwner(userInfo.id);
      }else{
        await this.props.house.fetchHouses();
      }
    } catch (error) {
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block", marginTop: "-100px" }}
            title={`Error !`}
            onConfirm={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
            {`${error.message}`}
          </SweetAlert>
        )
      });
    }
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  getHouseData = () => {
    const houses = this.props.house.getHousesJS();

    return houses.map((item, index) => {
      let ownerUserFullName = '';
      if (item.owner_user != null) {
        ownerUserFullName = `${item.owner_user.firstname || ''} ${item
          .owner_user.lastname || ''}`;
      }

      return {
        id: item.id,
        ownerUserId: '',
        name: item.name,
        ownerUserFullName: ownerUserFullName,
        shortDetail: item.short_detail,
        longDetail: item.long_detail,
        termAndCondition: item.term_and_condition,
        nearlyRestaurant: item.nearly_restaurant,
        nearlyLandmark: item.nearly_landmark,
        checkIn: item.check_in,
        checkOut: item.check_out,
        minPerson: item.min_person,
        maxPerson: item.max_person,
        status: item.status,
        ownerUser: item.owner_user,
        actions: (
          <div className="actions-center">
            {auth.getUserRole() === 'super_admin' && <Tooltip title="Price Setting" placement="top">
              <Button justIcon round
                onClick={() => {
                  this.props.history.push(`/admin/house/price-calendar/${item.id}`);
                }}
                color="primary">
                <Setting />
              </Button>
            </Tooltip>}
            &nbsp;&nbsp;&nbsp;
            <Tooltip title="Calendar" placement="top">
              <Button justIcon round
                onClick={() => {
                  this.props.history.push(`/admin/house/calendar/${item.id}`);
                }}
                color="rose">
                <Calendar />
              </Button>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;
             {auth.getUserRole() === 'super_admin' && <Tooltip title="Edit House" placement="top">
              <Button
                justIcon
                round
                // simple
                onClick={() => {
                  this.props.history.push(`/admin/house/${item.id}/edit`);
                }}
                color="warning"
                className="edit">
                <Edit />
              </Button>
            </Tooltip>
            }
            &nbsp;&nbsp;&nbsp;
            {auth.getUserRole() === 'super_admin' && <Tooltip title="Delete" placement="top">
              <Button
                justIcon
                round
                // simple
                onClick={() => this.onDeleteClick(item)}
                color="danger"
                className="remove">
                <Close />
              </Button>
            </Tooltip>
            }
          </div>
        ),
      };
    });
  };

  onDeleteClick = item => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: async () => {
        return await this.props.house.deleteHouse(item.id)
          .then(response => {
            if (response) { return response } else { throw response }
          })
          .catch(error => {
            Swal.showValidationMessage(error.message)
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        Swal.fire('Deleted!', '', 'success');
      }
    })
  };

  // onDeleteClick = item => {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: `You won't be able to revert this!`,
  //     type: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes, delete it!',
  //     reverseButtons: true
  //   }).then(async result => {
  //     if (result.value) {
  //       const result = await this.props.house.deleteHouse(item.id);
  //       if (result) {
  //         Swal.fire('Deleted!', '', 'success');
  //       } else {
  //         Swal.fire('Cancelled', '', 'error');
  //       }
  //     }
  //   });
  // };


  render() {
    const { classes } = this.props;
    const houses = this.getHouseData();

    return (
      <GridContainer>
        {this.state.alert}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Home />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>House List</h4>
              <CardIcon
                onClick={() => {
                  this.props.history.push(`/admin/house/create`);
                }}
                color="info"
                style={{
                  float: 'right',
                  borderRadius: '35px',
                  cursor: 'pointer',
                }}>
                <Add />{' '}
                <div
                  style={{
                    float: 'right',
                    paddingTop: '7px',
                    fontWeight: 'bold',
                    paddingRight: '6px',
                  }}>
                  Create House
                </div>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={houses}
                filterable
                columns={[
                  {
                    Header: 'ID',
                    accessor: 'id',
                    width: 80,
                  },
                  {
                    Header: 'Name',
                    accessor: 'name',
                  },
                  {
                    Header: 'Owner User',
                    accessor: 'ownerUserFullName',
                  },

                  {
                    Header: 'Status',
                    accessor: 'status',
                  },
                  {
                    Header: 'Actions',
                    headerStyle: { textAlign: 'center' },
                    accessor: 'actions',
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

Houses.propTypes = {
  classes: PropTypes.object,
};

export const page = inject('house')(observer(Houses));
export default withStyles(styles, { withTheme: true })(page);
