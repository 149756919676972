import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import { Tooltip } from '@material-ui/core';

// Util
import ReactTable from 'react-table';
import Swal from 'sweetalert2';

//Icon
import BankIcon from '@material-ui/icons/AccountBalance';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';

//Style
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';

const styles = {
  ...buttonStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

class BankAccounts extends Component {
  componentDidMount = () => {
    this.props.bankAccount.searchBankAccount();
  };

  onDeleteClick = item => {
    Swal.fire({
      title: 'คุณแน่ใจนะว่าจะทำการลบ?',
      text: 'คุณจะไม่สามารถย้อนกลับได้นะ!',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ใช่ ฉันต้องการลบ!',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: async () => {
        return await this.props.bankAccount
          .deleteBankAccount(item.id)
          .then(response => {
            if (response) {
              return response;
            } else {
              throw response;
            }
          })
          .catch(error => {
            Swal.showValidationMessage(error.message);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(result => {
      if (result.value) {
        Swal.fire('ลบสำเร็จ!', '', 'success');
      }
    });
  };

  getBankAccountsData = () => {
    const bankAccounts = this.props.bankAccount.getBankAccountsJS();

    return bankAccounts.map(item => {
      return {
        ...item,
        actions: (
          <div className="actions-center">
            <Tooltip title="Edit Bank Account" placement="top">
              <Button
                justIcon
                round
                // simple
                onClick={() => {
                  this.props.history.push(
                    `/admin/bank-account/${item.id}/edit`,
                  );
                }}
                color="warning"
                className="edit">
                <Edit />
              </Button>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;
            <Tooltip title="Delete Bank Account" placement="top">
              <Button
                justIcon
                round
                // simple
                onClick={() => this.onDeleteClick(item)}
                color="danger"
                className="remove">
                <Close />
              </Button>
            </Tooltip>
          </div>
        ),
      };
    });
  };

  render() {
    const { classes } = this.props;
    const bankAccounts = this.getBankAccountsData();

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <BankIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>House List</h4>
              <CardIcon
                onClick={() => {
                  this.props.history.push(`/admin/bank-account/create`);
                }}
                color="info"
                style={{
                  float: 'right',
                  borderRadius: '35px',
                  cursor: 'pointer',
                }}>
                <Add />{' '}
                <div
                  style={{
                    float: 'right',
                    paddingTop: '7px',
                    fontWeight: 'bold',
                    paddingRight: '6px',
                  }}>
                  Create Bank Account
                </div>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={bankAccounts || []}
                filterable
                columns={[
                  {
                    Header: 'ID',
                    accessor: 'id',
                    width: 80,
                  },
                  {
                    Header: 'Bank Name',
                    accessor: 'bankName',
                  },
                  {
                    Header: 'Account No',
                    accessor: 'accountNo',
                  },
                  {
                    Header: 'Account Name',
                    accessor: 'accountName',
                  },
                  {
                    Header: 'Branch',
                    accessor: 'branch',
                  },
                  {
                    Header: 'Status',
                    accessor: 'status',
                  },
                  {
                    Header: 'Actions',
                    headerStyle: { textAlign: 'center' },
                    accessor: 'actions',
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

BankAccounts.propTypes = {
  classes: PropTypes.object,
};

export const page = inject('bankAccount')(observer(BankAccounts));
export default withStyles(styles, { withTheme: true })(page);
