import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

import routes from 'Routes.js';

import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx';

var ps;

class Dashboard extends React.Component {
  state = {
    mobileOpen: false,
    miniActive: false,
    image: require('assets/img/sidebar-2.jpg'),
    color: 'blue',
    bgColor: 'black',
    hasImage: true,
    fixedClasses: 'dropdown',
    logo: require('assets/img/logo-white.png'),
  };

  mainPanel = React.createRef();

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', this.resizeFunction);
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
    window.removeEventListener('resize', this.resizeFunction);
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.mainPanel.current.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  handleImageClick = image => {
    this.setState({ image: image });
  };

  handleColorClick = color => {
    this.setState({ color: color });
  };

  handleBgColorClick = bgColor => {
    switch (bgColor) {
      case 'white':
        this.setState({ logo: require('assets/img/logo.svg') });
        break;
      default:
        this.setState({ logo: require('assets/img/logo-white.svg') });
        break;
    }
    this.setState({ bgColor: bgColor });
  };

  handleFixedClick = () => {
    if (this.state.fixedClasses === 'dropdown') {
      this.setState({ fixedClasses: 'dropdown show' });
    } else {
      this.setState({ fixedClasses: 'dropdown' });
    }
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  // getRoute = () => {
  //   return window.location.pathname !== "/admin/full-screen-maps";
  // };

  getActiveRoute = routes => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  getRoutes = routes => {
    return routes.map((prop, key) => {
      const { collapse, views, layout, component: Component } = prop;
      if (collapse) {
        return this.getRoutes(views);
      }
      if (prop.layout === '/admin') {
        return (
          <Route
            exact
            path={`${layout}${prop.path}`}
            // component={prop.component}
            render={({ staticContext, ...routeProps }) => (
              <ErrorBoundary>
                <Component {...routeProps} />
              </ErrorBoundary>
            )}
            key={key}
          />
          // <Route exact path="/app/devices/:id/edit" component={DevicesPage} />
          // <Route exact path="/app/devices/:id/view" component={DevicesPage} />
        );
      } else {
        return null;
      }
    });
  };

  sidebarMinimize = () => {
    this.setState({ miniActive: !this.state.miniActive });
  };

  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };

  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      ' ' +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf('Win') > -1,
      });
    const menuRoutes = routes.filter(val => !val.hidden);
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={menuRoutes}
          logoText={'Best Haven'}
          logo={this.state.logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          bgColor={this.state.bgColor}
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref={this.mainPanel}>
          <AdminNavbar
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            brandText={this.getActiveRoute(routes)}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {this.getRoutes(routes)}
                <Redirect from="/admin" to="/admin/booking-calendar" />
              </Switch>
            </div>
          </div>
          {/* {this.getRoute() ? <Footer fluid /> : null} */}
          <Footer fluid />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(appStyle)(Dashboard);
