import Calendar from 'views/Calendar/Calendar.jsx';
// import Dashboard from 'views/Dashboard/Dashboard.jsx';
import User from 'views/User/User.jsx';
import UserPage from 'views/User/UserPage.jsx';
import House from 'views/House/House.jsx';
import Houses from 'views/House/Houses.jsx';
import BankAccounts from 'views/BankAccount/BankAccounts.jsx';
import BankAccount from 'views/BankAccount/BankAccount.jsx';
import HouseIcon from 'views/HouseIcon/HouseIcons.jsx';
import PriceSettingPage from 'views/PriceSetting/PriceSettingPage.jsx';
import PriceCalendarPage from 'views/PriceCalendar/PriceCalendarPage.jsx';
// @material-ui/icons
// import Apps from "@material-ui/icons/Apps";
import EventBusyIcon from '@material-ui/icons/EventBusy';
import Contacts from '@material-ui/icons/Contacts';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import DateRange from '@material-ui/icons/DateRange';
import Person from '@material-ui/icons/Person';
import Home from '@material-ui/icons/Home';
import BankIcon from '@material-ui/icons/AccountBalance';
import Collections from '@material-ui/icons/Collections';
import InsertChartOutlined from '@material-ui/icons/InsertChartOutlined';
import BookingEdit from 'views/Booking/BookingEdit';
import LoginPage from 'views/Pages/LoginPage.jsx';
import ErrorPage from 'views/Pages/ErrorPage.jsx';
import Holiday from 'views/Holiday/Holiday.jsx';
import ReportPage from 'views/Report/ReportPage';
import ContactPage from 'views/Contact/ContactPage';

var dashRoutes = [
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   icon: DashboardIcon,
  //   component: Dashboard,
  //   layout: '/admin',
  //   hidden: false,
  // },
  {
    collapse: true,
    hidden: true,
    name: 'Pages',
    icon: DateRange,
    state: 'pageCollapse',
    views: [
      {
        path: '/login-page',
        name: 'Login Page',
        component: LoginPage,
        layout: '/auth',
        hidden: true,
      },
      {
        path: '/error-page',
        name: 'Error Page',
        component: ErrorPage,
        layout: '/auth',
        hidden: true,
      },
    ],
  },
  {
    path: '/booking-calendar',
    name: 'Calendar',
    icon: DateRange,
    component: Calendar,
    layout: '/admin',
    hidden: false,
  },
  {
    path: '/holidays',
    name: 'Holiday',
    icon: EventBusyIcon,
    component: Holiday,
    layout: '/admin',
    hidden: false,
  },
  {
    path: '/booking/:id',
    name: 'Booking',
    icon: DateRange,
    component: BookingEdit,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/booking/bid/:bid',
    name: 'Booking',
    icon: DateRange,
    component: BookingEdit,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/user',
    name: 'Users',
    icon: Person,
    component: User,
    layout: '/admin',
    hidden: false,
  },
  {
    path: '/user/create',
    name: 'UserView',
    icon: Person,
    component: UserPage,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/bank-account',
    name: 'Bank Accounts',
    icon: BankIcon,
    component: BankAccounts,
    layout: '/admin',
    hidden: false,
  },
  {
    path: '/bank-account/create',
    name: 'Bank Account Create',
    icon: Home,
    component: BankAccount,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/bank-account/:id/edit',
    name: 'Bank Account Edit',
    icon: Home,
    component: BankAccount,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/house',
    name: 'Houses',
    icon: Home,
    component: Houses,
    layout: '/admin',
    hidden: false,
  },
  {
    path: '/icon-house',
    name: 'House Icons',
    icon: Collections,
    component: HouseIcon,
    layout: '/admin',
    hidden: false,
  },
  {
    path: '/house/create',
    name: 'HouseView',
    icon: Home,
    component: House,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/house/:id/edit',
    name: 'HouseView',
    icon: Home,
    component: House,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/house/calendar/:id',
    name: 'Calendar',
    icon: DateRange,
    component: Calendar,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/house/price-settings/:id',
    name: 'Price Setting',
    icon: Person,
    component: PriceSettingPage,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/house/price-calendar/:id',
    name: 'Price Calendar',
    icon: Person,
    component: PriceCalendarPage,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/user/:id',
    name: 'UserView',
    icon: Person,
    component: UserPage,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/user/:id/edit',
    name: 'UserView',
    icon: Person,
    component: UserPage,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/report',
    name: 'Report',
    icon: InsertChartOutlined,
    component: ReportPage,
    layout: '/admin',
    hidden: false,
  },
  {
    path: '/contact',
    name: 'Contact',
    icon: Contacts,
    component: ContactPage,
    layout: '/admin',
    hidden: false,
  },
];

export default dashRoutes;
