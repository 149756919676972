import { extendObservable, toJS } from 'mobx';
// import camelcaseKeys from 'camelcase-keys';
import http from '../utils/http';
import camelcaseKeys from 'camelcase-keys';

const BankAccount = {
  id: '',
  bankName: '',
  branch: '',
  accountNo: '',
  accountName: '',
  status: '',
};

export class BookingEditStore {
  constructor() {
    extendObservable(this, {
      loading: true,
      bankAccount: BankAccount,
      bankAccounts: [],
      mode: 'create',
    });
  }

  //Bank Account
  resetBankAccount() {
    this.bankAccount = BankAccount;
    this.mode = 'create';
  }

  setBankAccount(data) {
    this.bankAccount = data;
  }

  setBankAccountProp(key, val) {
    this.bankAccount[key] = val;
  }

  getBankAccountJS() {
    return toJS(this.bankAccount);
  }

  //Bank Accounts
  resetBankAccounts() {
    this.bankAccounts = [];
  }

  setBankAccounts(data) {
    this.bankAccounts = data;
  }

  getBankAccountsJS() {
    return toJS(this.bankAccounts);
  }

  async fetchBankAccount(id) {
    try {
      this.loading = true;
      const response = await http.get(`/bank-accounts/${id}`);
      this.setBankAccount(camelcaseKeys(response || {}));
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async validateHasBankActive() {
    try {
      this.loading = true;
      const response = await http.get(`/bank-accounts/has-bank-active`);
      return response;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async searchBankAccount() {
    let result = [];
    try {
      result = await http.get(`/bank-accounts/`);
    } catch (error) {
      console.log('Error searchBankAccount: ' + error);
    }
    this.setBankAccounts(camelcaseKeys(result || []));
  }

  async saveBankAccount() {
    let result;
    const data = this.getBankAccountJS();

    if (data.id !== null && data.id !== undefined && data.id !== '') {
      console.log('## Update Bank Account');
      result = await http.put(`/bank-accounts/${data.id}`, data);
    } else {
      console.log('## Insert Bank Account');
      data.id = 0;
      result = await http.post(`/bank-accounts/`, data);
    }

    return result;
  }

  async deleteBankAccount(id) {
    this.loading = true;
    try {
      const response = await http.delete(`/bank-accounts/${id}`);
      if (response && response.status === 'success') {
        this.bankAccounts = this.bankAccounts.filter(val => val.id !== id);
      }
      return true;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }
}

const bookingEditStore = new BookingEditStore();
export default bookingEditStore;
