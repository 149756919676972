/*eslint-disable*/
import React from "react";
import { observer, inject } from 'mobx-react';
import { toJS } from "mobx";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from '@material-ui/core/FormLabel';
import Datetime from "react-datetime";
// core components
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardText from 'components/Card/CardText.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PreviewImage from "components/CustomUpload/PreviewImage.jsx";
import Button from 'components/CustomButtons/Button.jsx';
// import { Grid } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Swal from 'sweetalert2';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx';
import config from 'config';
import DropZone from '../../components/DropZone';

const styles = {
  ...validationFormsStyle,
  closeButton: {
    position: 'absolute',
    top: '-1px',
    right: '-1px',
    transform: 'translate(50%,-50%)',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    border: 'solid 3px white',
    background: 'rgb(244, 67, 53)',
    textAlign: 'center',
    cursor: 'pointer'
  }
};

export const dropZoneOption = {
  acceptFile: '.jpg,.png,.gif,.jpeg',
  maxSize: 10,
  limitationText:
    'สามารถอัพโหลดไฟล์นามสกุล (jpg, png, gif) ครั้งละ 1 ไฟล์และขนาดไม่เกิน 1 MB',
};

class BookingEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bid: '',
    }
  }

  async componentDidMount() {
    try {
      const { id, bid } = this.props.match.params;
      if (bid) this.setState({ bid })
      await this.props.bookingEdit.resetBooking();
      await this.props.bookingEdit.loadBooking(id ? id : bid, id ? 'id' : 'bid');
    } catch (error) {
      Swal.fire({
        title: 'ไม่พบข้อมูล',
        text: '',
        type: 'warning',
        showCancelButton: false,
        confirmButtonText: 'ตกลง',
      }).then((result) => {
        if (result.value) {
          this.props.history.push('/admin/booking-calendar');
        }
      })
    }
  }
  onInputChangePayment(e, index) {
    this.props.bookingEdit.onChangePayment(e.target.name, e.target.value, index);
  }
  handleRemove = (index, id) => {
    this.props.bookingEdit.onRemovePayments('paymentSlips', id, index);
  }


  async onUploadSlip(index) {
    if (index === 0) {
      await this.dropZone1.upload();
    } else {
      await this.dropZone2.upload();
    }
  }

  onUploadComplete1 = async (files) => {
    const url = files[0].res.url;
    this.setState({ isUploading1: false });
    // this.props.bookingEdit.onChangePayment('paymentSlip', url, 0);
    this.props.bookingEdit.onChangePayments('paymentSlips', url, 0);
  };

  onUploadComplete2 = async (files) => {
    const url = files[0].res.url;
    this.setState({ isUploading2: false });
    // this.props.bookingEdit.onChangePayment('paymentSlip', url, 1);
    this.props.bookingEdit.onChangePayments('paymentSlips', url, 1);
    // this.submitForm();
  };


  createPayment(payment, index , booking) {
    payment.paidDate = payment.paidDate ? payment.paidDate : new Date()
    const { classes } = this.props;
    return (
      <GridItem xs={12} sm={12} md={12} lg={12} key={index} className={classes.contentDate}>
        <div style={{ textAlign: "left", width: "100%" }}>
          <fieldset>
            <legend style={{ fontSize: "14px" }}>Installment {index + 1}</legend>
            <GridContainer>
              <GridItem xs={12} sm={6} md={8}>
              <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <FormLabel className={classes.labelHorizontal}>
                      Bank Account Name
                  </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <div style={{ textAlign: "left", width: "100%" }}>
                      <CustomInput
                        id="bankAccount"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: 'text',
                          value: booking.bankAccount.bank_name +' : '+ booking.bankAccount.account_name || '',
                          name: 'bankAccount',
                          disabled: true
                        }}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <FormLabel className={classes.labelHorizontal}>
                      Bank Account Number
                  </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <div style={{ textAlign: "left", width: "100%" }}>
                      <CustomInput
                        id="bankAccountNo"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: 'text',
                          value: booking.bankAccount.account_no || '',
                          name: 'bankAccountNo',
                          disabled: true
                        }}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <FormLabel className={classes.labelHorizontal}>
                      Amount
                  </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <div style={{ textAlign: "left", width: "100%" }}>
                      <CustomInput
                        id="amount"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: event =>
                            this.onInputChangePayment(event, index),
                          type: 'text',
                          value: payment.amount || '',
                          name: 'amount',
                          disabled: payment.disable
                        }}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <FormLabel className={classes.labelHorizontal}>
                      Status
                  </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <div style={{ textAlign: "left", width: "100%" }}>
                      <FormControl fullWidth className={classes.contentDate}>
                        <Select
                          disabled={payment.disable}
                          id="status"
                          name="status"
                          value={payment.status || "pending"}
                          onChange={(e) => this.onInputChangePayment(e, index)}
                        // pending, paid, cancel, reject, delete
                        >
                          <MenuItem value="">
                            <em>-</em>
                          </MenuItem>
                          <MenuItem value={"pending"}>pending</MenuItem>
                          <MenuItem value={"paid"}>paid</MenuItem>
                          <MenuItem value={"cancel"}>cancel</MenuItem>
                          <MenuItem value={"reject"}>reject</MenuItem>
                          <MenuItem value={"delete"}>delete</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <FormLabel className={classes.labelHorizontal}>
                      Paid Date
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <div className={classes.contentDate}>
                      <Datetime
                        locale="en"
                        disabled={payment.disable}
                        timeFormat={false}
                        inputProps={{
                          // placeholder: "Date Time",
                        }}
                        value={moment(payment.paidDate).tz('Asia/Bangkok').format('DD/MM/YYYY')}
                        id="paidDate"
                        name="paidDate"
                        onChange={(e) => this.onChangeDatePayment(e, index)}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <FormLabel className={classes.labelHorizontal}>
                      Bill Slip
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <DropZone
                      ref={e => {
                        (index === 0) ? this.dropZone1 = e : this.dropZone2 = e;
                      }}
                      placeholder="Click here to upload a photo."
                      url={`${config.api.url}/uploads`}
                      acceptedFiles={dropZoneOption.acceptFile}
                      maxFileSize={dropZoneOption.maxSize}
                      dirName="payment"
                      // fileName=""
                      onAllComplete={(index === 0) ? this.onUploadComplete1 : this.onUploadComplete2}
                    />
                    <Button
                      fullWidth
                      color="primary"
                      onClick={() => this.onUploadSlip(index)}
                    >
                      Upload
                      </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12} sm={6} md={4}>
                <GridContainer>
                  {payment.paymentSlips.map(val =>
                    <GridItem xs={12} sm={12} md={12}>
                      <PreviewImage
                        status={payment.disable}
                        classes={classes}
                        imageProps={val.url}
                        index={index}
                        url={val.url}
                        disabled={payment.disable}
                        removeButtonProps={{
                          color: "danger",
                          round: false
                        }}
                        handleRemove={this.handleRemove}
                      />
                    </GridItem>
                  )}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </fieldset>
        </div>
      </GridItem>
    );
  }

  onInputChangeHouse(e) {
    this.props.bookingEdit.onChangeHouse(e.target.name, e.target.value);
  }

  onInputChangeBooking(e) {
    this.props.bookingEdit.onChangeBooking(e.target.name, e.target.value);
  }

  onChangeDate(date, key) {
    const dateFormat = moment(date).format();
    this.props.bookingEdit.onChangeBooking(key, dateFormat);
  }

  onChangeDatePayment(date, index) {
    // value={moment(payment.paidDate).utcOffset('420').format('YYYY-MM-DD HH:mm')}
    const dateFormat = moment(date).format();
    this.props.bookingEdit.onChangePayment('paidDate', dateFormat, index);
  }

  onInputChangePayment(e, index) {
    this.props.bookingEdit.onChangePayment(e.target.name, e.target.value, index);
  }

  validation() {
    const { bookings } = toJS(this.props.bookingEdit);
    if (!bookings.startDate) {
      Swal.fire({
        type: 'error',
        title: 'Sorry!',
        text: `กรุณาระบุวันเริ่มต้น`,
      });
      return false;
    }
    if (!bookings.endDate) {
      Swal.fire({
        type: 'error',
        title: 'Sorry!',
        text: `กรุณาระบุวันสิ้นสุด`,
      });
      return false;
    }
    if (!bookings.adults) {
      Swal.fire({
        type: 'error',
        title: 'Sorry!',
        text: `กรุณาระบุจำนวนผู้ใหญ่`,
      });
      return false;
    }
    if (!bookings.firstname) {
      Swal.fire({
        type: 'error',
        title: 'Sorry!',
        text: `กรุณาระบุชื่อ`,
      });
      return false;
    }
    if (!bookings.lastname) {
      Swal.fire({
        type: 'error',
        title: 'Sorry!',
        text: `กรุณาระบุนามสกุล`,
      });
      return false;
    }
    if (!bookings.mobile) {
      Swal.fire({
        type: 'error',
        title: 'Sorry!',
        text: `กรุณาระบุเบอร์โทรศัพท์`,
      });
      return false;
    }
    if (!bookings.email) {
      Swal.fire({
        type: 'error',
        title: 'Sorry!',
        text: `กรุณาระบุอีเมล์`,
      });
      return false;
    }
    if (!bookings.price) {
      Swal.fire({
        type: 'error',
        title: 'Sorry!',
        text: `กรุณาระบุราคา`,
      });
      return false;
    }
    if (!bookings.status) {
      Swal.fire({
        type: 'error',
        title: 'Sorry!',
        text: `กรุณาระบุสถานะการชำระเงิน`,
      });
      return false;
    }
    if (bookings.status === 'paid') {
      const total = (bookings.payments || []).reduce((sum, { amount, status }) =>
        status === 'paid' ? sum + (+amount) : sum, 0);
      // const insurancePrice = bookings.house.insurancePrice || 0;
      // if ((+total) < (+bookings.price + bookings.vatPrice + insurancePrice)) {
      if ((+total) < (+bookings.price + bookings.vatPrice)) {  
        Swal.fire({
          type: 'error',
          title: 'จ่ายเงินยังไม่ครบ',
          html: ` ราคาที่พัก(รวมภาษี) : ${bookings.price + bookings.vatPrice} บาท<br/>ยอดจ่าย : ${total} บาท<br/> ขาดอีก : ${(bookings.price + bookings.vatPrice) - total} บาท`,
        });
        return false;
      }
    }
    return true;
  }

  async onSaveChange() {
    if (!this.validation()) return;
    Swal.fire({
      title: 'Are you sure?',
      text: "Edit Bookoing",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: async () => {
        return await this.props.bookingEdit.onSaveChange()
          .then(response => {
            if (response) {
              return response
            } else {
              throw response
            }
          })
          .catch(error => {
            Swal.showValidationMessage(error)
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value && result.value.house_id) {
        const houseId = result.value.house_id
        Swal.fire({
          type: 'success',
          title: 'บันทึก!',
          text: 'แก้ไขข้อมูลเสร็จเรียบร้อย',
        }).then(result => {
          if (result.value) {
            this.props.history.push(`/admin/house/calendar/${houseId}`);
          }
        });
      }
    })
  }

  checkIsNo(inputTxt) {
    const phoneNo = /^\d+$/;
    if (inputTxt.match(phoneNo)) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    let { bookings } = toJS(this.props.bookingEdit);
    let { house } = bookings;
    console.log('bookings',bookings);
    let paymentItem = bookings.payments.map((payment, index) => {
      return this.createPayment(payment, index, bookings);
    });
    const adultsList = [];

    for (let i = 1; i <= 50; i++) {
      adultsList.push(i);
    }
    const { classes } = this.props;
    return (

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" text>
              <CardText color="primary">
                <h4 className={classes.cardTitle}>
                  {'Booking Edit'}
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                {this.state.bid && <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Booking ID
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <CustomInput
                      id="bid"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.onInputChangeHouse(event),
                        type: 'text',
                        value: this.state.bid,
                        name: 'bid',
                        disabled: true
                      }}
                    />
                  </GridItem>
                </GridContainer>}

                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      House
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <CustomInput
                      id="house"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.onInputChangeHouse(event),
                        type: 'text',
                        value: house.name || '',
                        name: 'house',
                        disabled: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Check In - Out
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <div className={classes.contentDate}>
                      <Datetime
                        locale="en"
                        timeFormat={false}
                        inputProps={{
                          placeholder: "Start",
                        }}
                        value={moment(bookings.startDate).tz('Asia/Bangkok').format("DD/MM/YYYY")}
                        onChange={(date) => this.onChangeDate(date, `startDate`)}
                      // renderInput={this.renderStartInput} 
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <div className={classes.contentDate}>
                      <Datetime
                        locale="en"
                        timeFormat={false}
                        inputProps={{
                          placeholder: "End",
                        }}
                        value={moment(bookings.endDate).tz('Asia/Bangkok').format("DD/MM/YYYY")}
                        onChange={(date) => this.onChangeDate(date, `endDate`)}
                      // renderInput={this.renderEndInput} 
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Firstname
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <CustomInput
                      // success={this.state.firstnameState === 'success'}
                      // error={true}
                      id="firstname"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.onInputChangeBooking(event),
                        type: 'text',
                        value: `${bookings.firstname || ''}`,
                        name: 'firstname',
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Lastname
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <CustomInput
                      id="lastname"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.onInputChangeBooking(event),
                        type: 'text',
                        value: `${bookings.lastname || ''}`,
                        name: 'lastname',
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Mobile
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <CustomInput
                      id="mobile"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.onInputChangeBooking(event),
                        type: 'number',
                        value: `${bookings.mobile || ''}`,
                        name: 'mobile',
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Email
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <CustomInput
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.onInputChangeBooking(event),
                        type: 'email',
                        value: `${bookings.email || ''}`,
                        name: 'email',
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Adults
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={10} sm={3}>
                    <FormControl fullWidth className={classes.contentDate}>
                      <Select
                        id="adults"
                        name="adults"
                        value={bookings.adults || ""}
                        onChange={(e) => this.onInputChangeBooking(e)}
                      >
                        <MenuItem value="">
                          <em>-</em>
                        </MenuItem>
                        {adultsList.map(val => {
                          return <MenuItem value={val}>{val}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Children
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={10} sm={3}>
                    <FormControl fullWidth className={classes.contentDate}>
                      <Select
                        id="adultsKid"
                        name="adultsKid"
                        value={bookings.adultsKid || ""}
                        onChange={(e) => this.onInputChangeBooking(e)}
                      >
                        <MenuItem value="">
                          <em>-</em>
                        </MenuItem>
                        {adultsList.map(val => {
                          return <MenuItem value={val}>{val}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>

                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Deposit
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <CustomInput
                      id="deposit"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'number',
                        value: bookings.house.insurancePrice || 0,
                        name: 'deposit',
                        disabled: true
                      }}
                    />
                  </GridItem>

                </GridContainer>


                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Price
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <CustomInput
                      id="price"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.onInputChangeBooking(event),
                        type: 'number',
                        value: bookings.price || '',
                        name: 'price',
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Vat ({bookings.vatRate || 0}%)
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <CustomInput
                      id="vatPrice"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.onInputChangeBooking(event),
                        type: 'number',
                        value: bookings.vatPrice || 0,
                        name: 'vatPrice'
                      }}
                    />
                  </GridItem>
                </GridContainer>

                {/* <GridItem xs={12} sm={12} md={12}>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={4} md={2} textAlign="right">
                      <div style={{ textAlign: "right", width: "100%" }}>
                        Code
                </div>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <div style={{ textAlign: "left", width: "100%" }}>
                        <TextField
                          id="code"
                          name="code"
                          label="Code"
                          style={{ margin: 8 }}
                          placeholder="Code"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="filled"
                          value={bookings.code || ""}
                          onChange={(e) => this.onInputChangeBooking(e)}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem> */}

                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Payment
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    {paymentItem}
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Status
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <FormControl fullWidth className={classes.contentDate}>
                      <Select
                        id="status"
                        name="status"
                        value={bookings.status || "pending"}
                        onChange={(e) => this.onInputChangeBooking(e)}
                      >
                        <MenuItem value="">
                          <em>-</em>
                        </MenuItem>
                        <MenuItem value={"pending"}>pending</MenuItem>
                        <MenuItem value={"paid"}>paid</MenuItem>
                        <MenuItem value={"cancel"}>cancel</MenuItem>
                        <MenuItem value={"reject"}>reject</MenuItem>
                        <MenuItem value={"delete"}>delete</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Notation
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <CustomInput
                      id="notation"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.onInputChangeBooking(event),
                        type: 'text',
                        value: bookings.note || '',
                        name: 'note',
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button color="primary" onClick={() => this.onSaveChange()}>
                {'Save'}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

BookingEdit.propTypes = {
  classes: PropTypes.object
};

export const page = inject('bookingEdit')(observer(BookingEdit));
// export default withStyles(validationFormsStyle)(page);
export default withStyles(styles, { withTheme: true })(page);
