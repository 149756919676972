import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
// import config from 'config';
// material ui icons
// import Check from "@material-ui/icons/Check";
import Close from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import Switch from '@material-ui/core/Switch';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardText from 'components/Card/CardText.jsx';
// import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Swal from 'sweetalert2';
// style for this view
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
// import config from 'config';
// import DropZone from '../../components/DropZone';

export const dropZoneOption = {
  acceptFile: '.jpg,.png,.gif,.jpeg',
  maxSize: 10,
  limitationText:
    'สามารถอัพโหลดไฟล์นามสกุล (jpg, png, gif) ครั้งละ 1 ไฟล์และขนาดไม่เกิน 10 MB',
};

class UserPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploadingMaps: false,
      usernameState: '',
      firstnameState: '',
      lastnameState: '',
      nicknameState: '',
      mobileOneState: '',
      mobileTwoState: '',
      typeEmailState: '',
      roleState: '',
      statusState: '',
      equalToState: '',
      password: '',
      confirmPassword: '',
    };
    this.submitClick = this.submitClick.bind(this);
  }

  async componentDidMount() {
    let id = this.props.match.params.id;
    if (id) {
      await this.props.user.getUser(id);
      this.setState({
        isUploadingMaps: false,
        usernameState: 'success',
        firstnameState: 'success',
        lastnameState: 'success',
        nicknameState: 'success',
        mobileOneState: 'success',
        mobileTwoState: 'success',
        typeEmailState: 'success',
        roleState: 'success',
        statusState: 'success',
        equalToState: 'success',
        password: '',
        confirmPassword: '',
      });
    } else {
      await this.props.user.resetUser();
    }
  }

  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }

  verifyNumber(value) {
    var numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo, maxValue) {
    if (
      event.target.name !== 'password' && event.target.name !== 'confirmPassword'
    ) {
      if (event.target.name === 'status') {
        this.props.user.changeFieldUser(
          event.target.name,
          event.target.checked ? 'active' : 'in_active',
        );
      } else {
        this.props.user.changeFieldUser(event.target.name, event.target.value);
      }
    } else {
      this.props.user.changeFieldUser(event.target.name, event.target.value);
      this.setState({ [stateName]: event.target.value });
    }
    switch (type) {
      case 'lengthPassword':
        if (this.state.confirmPassword) {
          if (
            this.compare(
              stateName === 'confirmPassword'
                ? this.state.password
                : this.state.confirmPassword,
              event.target.value,
            ) &&
            this.verifyLength(event.target.value, 6)
          ) {
            this.setState({ equalToState: 'success' });
          } else {
            this.setState({ equalToState: 'error' });
          }
        } else {
          if (this.verifyLength(event.target.value, 6)) {
            this.setState({ equalToState: 'success' });
          } else {
            this.setState({ equalToState: 'error' });
          }
        }
        break;
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      case 'checkbox':
        if (event.target.checked) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      case 'max-length':
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      case 'min-value':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo
        ) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      case 'max-value':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value <= stateNameEqualTo
        ) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      case 'range':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      default:
        break;
    }
  }

  printError(code) {
    let message = 'ไม่สามารถบันทึกข้อมูลได้';
    switch (code) {
      case 'validation_failed':
        message = 'กรุณากรอกข้อมูลให้ถูกต้องครบถ้วน';
        break;
      case 'data_not_found':
        message = 'ไม่พบข้อมูล';
        break;
      default:
        break;
    }
    return message;
  }

  validationForm() {
    let { mode } = this.props.user;
    let statusValidation = true;
    if (
      this.state.usernameState === '' ||
      this.state.usernameState === 'error'
    ) {
      this.setState({ usernameState: 'error' });
      statusValidation = false;
    }
    if (mode === 'create') {
      if (this.state.confirmPassword === '' || this.state.password === '') {
        this.setState({ equalToState: 'error' });
        statusValidation = false;
      }
    }
    if (this.state.typeEmailState === '') {
      this.setState({ typeEmailState: 'error' });
      statusValidation = false;
    }
    if (this.state.equalToState === '') {
      this.setState({ equalToState: 'error' });
      statusValidation = false;
    }
    if (this.state.mobileOneState === '') {
      this.setState({ mobileOneState: 'error' });
      statusValidation = false;
    }
    if (this.state.firstnameState === '') {
      this.setState({ firstnameState: 'error' });
      statusValidation = false;
    }
    if (this.state.lastnameState === '') {
      this.setState({ lastnameState: 'error' });
      statusValidation = false;
    }
    return statusValidation;
  }

  async updateUser() {
    if (this.validationForm()) {
      let { mode } = this.props.user;
      let id = this.props.match.params.id;
      if (id && mode === 'edit') {
        try {
          await this.props.user.updateUser(id);
          Swal.fire({
            type: 'success',
            title: 'Success!',
            text: 'Update user success',
          }).then(result => {
            if (result.value) {
              this.props.history.push(`/admin/user`);
            }
          });
        } catch (error) {
          Swal.fire({
            type: 'error',
            title: 'Sorry!',
            text: error,
          });
        }
      } else {
        try {
          await this.props.user.createUser();
          Swal.fire({
            type: 'success',
            title: 'Success!',
            text: 'Create user success',
          }).then(result => {
            if (result.value) {
              this.props.history.push(`/admin/user`);
            }
          });
        } catch (error) {
          Swal.fire({
            type: 'error',
            title: 'Sorry!',
            text: error,
          });
        }
      }
    }
  }

  onUpload = () => {
    this.setState({ isUploading: true }, () => {
      this.dropZone.upload();
    });
  };

  onUploadComplete = async files => {
    const url = files[0].res.url;
    this.setState({ isUploading: false });
    alert(url);
    await this.updateUser();
  };

  async submitClick() {
    if (this.validationForm()) {
        await this.updateUser();
    }
  }

  render() {
    const { classes } = this.props;
    let { user, mode } = this.props.user;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" text>
              <CardText color="primary">
                <h4 className={classes.cardTitle}>
                  {mode === 'edit' ? 'Edit User' : 'Create User'}{' '}
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Username
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <CustomInput
                      success={this.state.usernameState === 'success'}
                      error={this.state.usernameState === 'error'}
                      id="username"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'username', 'length', 3),
                        type: 'text',
                        value: `${user.username || ''}`,
                        name: 'username',
                        endAdornment:
                          this.state.usernameState === 'error' ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                              undefined
                            ),
                      }}
                    />
                  </GridItem>
                </GridContainer>
                {(mode !== 'edit') &&
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Password
                    </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                      <CustomInput
                        success={this.state.equalToState === 'success'}
                        error={this.state.equalToState === 'error'}
                        id="equalto"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: event =>
                            this.change(event, 'password', 'lengthPassword'),
                          type: 'text',
                          name: 'password',
                          endAdornment:
                            this.state.equalToState === 'error' ? (
                              <InputAdornment position="end">
                                <Close className={classes.danger} />
                              </InputAdornment>
                            ) : (
                                undefined
                              ),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Confirm Password
                    </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                      <CustomInput
                        success={this.state.equalToState === 'success'}
                        error={this.state.equalToState === 'error'}
                        id="whichequalto"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: event =>
                            this.change(
                              event,
                              'confirmPassword',
                              'lengthPassword',
                            ),
                          type: 'text',
                          name: 'confirmPassword',
                          endAdornment:
                            this.state.equalToState === 'error' ? (
                              <InputAdornment position="end">
                                <Close className={classes.danger} />
                              </InputAdornment>
                            ) : (
                                undefined
                              ),
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                }

                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Firstname
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <CustomInput
                      success={this.state.firstnameState === 'success'}
                      error={this.state.firstnameState === 'error'}
                      id="firstname"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'firstname', 'length', 1),
                        type: 'text',
                        value: `${user.firstname || ''}`,
                        name: 'firstname',
                        endAdornment:
                          this.state.firstnameState === 'error' ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                              undefined
                            ),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Lastname
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <CustomInput
                      success={this.state.lastnameState === 'success'}
                      error={this.state.lastnameState === 'error'}
                      id="lastname"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'lastname', 'length', 1),
                        type: 'text',
                        value: `${user.lastname || ''}`,
                        name: 'lastname',
                        endAdornment:
                          this.state.lastnameState === 'error' ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                              undefined
                            ),
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Nickname
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <CustomInput
                      success={this.state.nicknameState === 'success'}
                      error={this.state.nicknameState === 'error'}
                      id="nickname"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'nickname', 'length', 1),
                        type: 'text',
                        value: `${user.nickname || ''}`,
                        name: 'nickname',
                        endAdornment:
                          this.state.nicknameState === 'error' ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                              undefined
                            ),
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Mobile 1
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <CustomInput
                      success={this.state.mobileOneState === 'success'}
                      error={this.state.mobileOneState === 'error'}
                      id="mobileOne"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'mobileOne', 'number'),
                        type: 'number',
                        name: 'mobileOne',
                        value: `${user.mobileOne || ''}`,
                        endAdornment:
                          this.state.mobileOneState === 'error' ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                              undefined
                            ),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Mobile 2
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <CustomInput
                      success={this.state.mobileTwoState === 'success'}
                      error={this.state.mobileTwoState === 'error'}
                      id="mobileTwo"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'mobileTwo', 'number'),
                        type: 'number',
                        name: 'mobileTwo',
                        value: `${user.mobileTwo || ''}`,
                        endAdornment:
                          this.state.mobileTwoState === 'error' ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                              undefined
                            ),
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Email
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <CustomInput
                      success={this.state.typeEmailState === 'success'}
                      error={this.state.typeEmailState === 'error'}
                      id="typeemail"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'typeEmail', 'email'),
                        type: 'email',
                        name: 'email',
                        value: `${user.email || ''}`,
                        endAdornment:
                          this.state.typeEmailState === 'error' ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                              undefined
                            ),
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel
                      className={
                        classes.labelHorizontal +
                        ' ' +
                        classes.labelHorizontalRadioCheckbox
                      }>
                      Role
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        ' ' +
                        classes.checkboxAndRadioHorizontal
                      }>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={user.role === 'super_admin'}
                            onChange={event => this.change(event)}
                            value="super_admin"
                            name="role"
                            aria-label="super admin"
                            icon={
                              <FiberManualRecord
                                className={classes.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classes.radioChecked}
                              />
                            }
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label="Super admin"
                      />
                    </div>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        ' ' +
                        classes.checkboxAndRadioHorizontal
                      }>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={user.role === 'admin'}
                            // onChange={this.handleChange}
                            onChange={event => this.change(event)}
                            value="admin"
                            name="role"
                            aria-label="admin"
                            icon={
                              <FiberManualRecord
                                className={classes.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classes.radioChecked}
                              />
                            }
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label="Admin"
                      />
                    </div>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        ' ' +
                        classes.checkboxAndRadioHorizontal
                      }>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={user.role === 'user'}
                            // onChange={this.handleChange}
                            onChange={event => this.change(event)}
                            value="user"
                            name="role"
                            aria-label="user"
                            icon={
                              <FiberManualRecord
                                className={classes.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classes.radioChecked}
                              />
                            }
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label="User"
                      />
                    </div>
                  
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel
                      className={
                        classes.labelHorizontal +
                        ' ' +
                        classes.labelHorizontalRadioCheckbox
                      }>
                      Status
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        ' ' +
                        classes.checkboxAndRadioHorizontal
                      }>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={user.status === 'active'}
                            onChange={event => this.change(event)}
                            name="status"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                        }}
                        label={user.status === 'active' ? 'Active' : 'Inactive'}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                {/* <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel
                      className={
                        classes.labelHorizontal +
                        ' ' +
                        classes.labelHorizontalRadioCheckbox
                      }>
                      Upload
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <DropZone
                      ref={e => {
                        this.dropZone = e;
                      }}
                      placeholder="Click here to upload a photo."
                      url={`${config.api.url}/uploads`}
                      acceptedFiles={dropZoneOption.acceptFile}
                      maxFileSize={dropZoneOption.maxSize}
                      dirName="user"
                      // fileName=""
                      onAllComplete={this.onUploadComplete}
                    />
                  </GridItem>
                </GridContainer> */}
              </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button color="primary" onClick={this.submitClick}>
                {mode === 'edit' ? 'Save' : 'Submit'}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

UserPage.propTypes = {
  classes: PropTypes.object,
};

export const page = inject('user')(observer(UserPage));
export default withStyles(validationFormsStyle, sweetAlertStyle)(page);
