import React from 'react';
// used for making the prop types of this component
import PropTypes from 'prop-types';

// core components
import Button from 'components/CustomButtons/Button.jsx';

import defaultImage from 'assets/img/image_placeholder.jpg';
import defaultAvatar from 'assets/img/placeholder.jpg';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

class PreviewImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
    };
  }
  fileInput = React.createRef();

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleRemove = () => {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
    });
    this.fileInput.current.value = null;
  };
  render() {
    var { imageProps, index, url, classes ,status} = this.props;
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {'Bill Slp | No.' + (index + 1)}
          </DialogTitle>
          <DialogContent>
            {imageProps && <img width="100%" src={imageProps} alt="..." />}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="danger" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <div className="fileinput text-center" style={{ position: 'relative' }}>
          {(imageProps&&!status) && <div
            className={classes.closeButton}
            onClick={() => {
              this.props.handleRemove(index, url);
            }}>
            <CloseIcon style={{ color: 'white' }} />
          </div>}
          <div
            className={'thumbnail'}
            style={{
              maxWidth: '100%',
              padding: '5px',
              backgroundColor: '#eeeeee',
            }}
            onClick={this.handleClickOpen}>
            {imageProps && <img width="100%" src={imageProps} alt="..." />}
          </div>
          {/* <div>
            {imageProps && (
              <span>
                <Button
                  {...removeButtonProps}
                  onClick={() => this.props.handleRemove(index, url)}>
                  <i className="fas fa-times" /> Remove
                </Button>
              </span>
            )}
          </div> */}
        </div>
      </div>
    );
  }
}

PreviewImage.propTypes = {
  removeButtonProps: PropTypes.object,
  imageProps: PropTypes.string,
  index: PropTypes.number,
};

export default PreviewImage;
