import { extendObservable } from 'mobx';
import camelcaseKeys from 'camelcase-keys';
import http from '../utils/http';
import moment from 'moment-timezone';
import auth from 'utils/auth';

const initialBooking = {
  id: '',
  bid: '',
  startDate: '',
  endDate: '',
  houseId: '',
  adults: 2,
  adultsKid: 0,
  firstname: '',
  lastname: '',
  mobile: '',
  email: '',
  price: '',
  vatPrice: '',
  vatRate: '',
  totalPrice: '',
  code: '',
  status: '',
  createdBy: '',
  createdAt: '',
  updatedBy: '',
  updatedAt: '',
  note: '',
  house: {
    createdBy: '',
    createdAt: '',
    updatedBy: '',
    updatedAt: '',
    id: '',
    ownerUserId: '',
    name: '',
    shortDetail: '',
    longDetail: '',
    termAndCondition: '',
    nearlyRestaurant: '',
    nearlyLandmark: '',
    checkIn: '',
    checkOut: '',
    minPerson: '',
    maxPerson: '',
    status: '',
    insurancePrice: '',
  },
  payments: [
    {
      createdBy: '',
      createdAt: '',
      updatedBy: '',
      updatedAt: '',
      id: '',
      bookingId: '',
      paidDate: new Date(),
      amount: 0,
      installmentNo: '',
      paymentSlip: '',
      paymentSlips: [],
      status: 'pending',
    },
    // {
    //   createdBy: '',
    //   createdAt: '',
    //   updatedBy: '',
    //   updatedAt: '',
    //   id: '',
    //   bookingId: '',
    //   paidDate: new Date(),
    //   amount: 0,
    //   installmentNo: '',
    //   paymentSlip: '',
    //   paymentSlips: [],
    //   status: 'pending',
    // },
  ],
  bankAccount: {
    account_name: '',
    account_no: '',
    bank_name: '',
    branch: '',
  },
};

export class BookingEditStore {
  constructor() {
    extendObservable(this, {
      loading: true,
      bookings: initialBooking,
      dataReport: [
        {
          createdAt: '',
          startDate: '',
          endDate: '',
          houseName: '',
          personAmount: '',
          deposit: '',
          remaining: '',
          insurance: '',
          saleName: '',
        },
      ],
    });
  }

  resetBooking() {
    this.bookings = initialBooking;
  }

  onChangeBooking(key, val) {
    this.bookings[key] = val;
  }

  onChangeHouse(key, val) {
    this.bookings.house[key] = val;
  }

  onChangePayment(key, val, index) {
    this.bookings.payments[index][key] = val;
  }

  onChangePayments(key, val, index) {
    let slips = this.bookings.payments[index][key];
    slips.push({ url: val });
    this.bookings.payments[index][key] = slips;
  }

  onRemovePayments(key, url, index) {
    let slips = this.bookings.payments[index][key];
    slips = slips.filter(val => val.url !== url);
    this.bookings.payments[index][key] = slips;
  }

  setBooking(bookings) {
    let tmpBookings = camelcaseKeys(bookings);
    tmpBookings.house = camelcaseKeys(tmpBookings.house);
    const { payments } = tmpBookings;
    const tmpPayments = this.bookings.payments.map((val, index) => {
      if (payments[index]) {
        val = payments[index];
        if (auth.getUserRole() !== 'super_admin') {
          if (
            +payments[index].amount === 0.0 &&
            (!payments[index].status || payments[index].status === 'pending')
          ) {
            val.disable = false;
          } else {
            val.disable = true;
          }
        } else {
          val.disable = false;
        }
      }
      return camelcaseKeys(val);
    });
    let booking = { ...tmpBookings, payments: [...tmpPayments] };
    this.bookings = camelcaseKeys(booking);
  }

  async onSaveChange() {
    const startDate = moment(this.bookings.startDate)
      .startOf('day')
      .format();
    const convertStartDate = moment(startDate)
      .add(12, 'hours')
      .format();
    const endDate = moment(this.bookings.endDate)
      .startOf('day')
      .format();
    const convertEndDate = moment(endDate)
      .add(719, 'minutes')
      .format();
    const totoalPrice = +this.bookings.price + +this.bookings.vatPrice;
    let data = {
      start_date: convertStartDate,
      end_date: convertEndDate,
      house_id: this.bookings.houseId,
      adults: this.bookings.adults,
      adults_kid: this.bookings.adultsKid,
      firstname: this.bookings.firstname,
      lastname: this.bookings.lastname,
      mobile: this.bookings.mobile,
      email: this.bookings.email,
      price: this.bookings.price,
      vat_price: this.bookings.vatPrice,
      vat_rate: this.bookings.vatRate,
      total_price: totoalPrice,
      status: this.bookings.status,
      note: this.bookings.note,
      payments: this.bookings.payments.map((val, index) => {
        if (val.id) {
          return {
            id: val.id,
            paid_date: val.paidDate || '',
            amount: val.amount,
            installment_no: val.installmentNo || 0,
            payment_slip: val.paymentSlip || '',
            payment_slips: val.paymentSlips || [],
            status: val.status,
          };
        } else {
          return {
            paid_date: val.paidDate || '',
            amount: val.amount,
            installment_no: val.installmentNo || 0,
            payment_slip: val.paymentSlip || '',
            payment_slips: val.paymentSlips || [],
            status: val.status,
          };
        }
      }),
    };
    try {
      const response = await http.put(`/bookings/${this.bookings.id}`, data);
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async loadBooking(id, type) {
    this.loading = true;
    try {
      const uri =
        type === 'id'
          ? `/bookings/${id}?show_house=true&show_payment=true`
          : `/bookings/${id}/bid?show_house=true&show_payment=true`;
      const response = await http.get(uri);
      if (response) {
        this.setBooking(response);
      }
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }
}

const bookingEditStore = new BookingEditStore();
export default bookingEditStore;
