import { extendObservable, toJS } from 'mobx';
// import config from 'config';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import auth from '../utils/auth';
import http from '../utils/http';

const initialUser = {
  username: '',
  password: '',
  firstname: '',
  lastname: '',
  nickname: '',
  mobileOne: '',
  mobileTwo: '',
  role: 'user',
  status: 'active',
  email: '',
};

export class UserStore {
  constructor() {
    extendObservable(this, {
      loading: false,
      users: [],
      user: initialUser,
      mode: 'create',
    });
  }

  setUsers(users) {
    this.users = users;
  }

  setUser(user) {
    this.user = camelcaseKeys(user);
  }

  getUserJS() {
    return toJS(this.user);
  }

  getUsersJS() {
    return toJS(this.users);
  }

  changeFieldUser(task, data) {
    this.user[task] = data;
  }

  async loadUser() {
    this.loading = true;
    try {
      const users = await http.get('/users');
      this.setUsers(users ? users.filter(user => user.status !== 'deleted') : []);
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  resetUser() {
    this.user = initialUser;
    this.mode = 'create';
  }

  async getUser(id) {
    this.loading = true;
    try {
      const user = await http.get(`/users/${id}`);
      if (user) {
        this.setUser(user);
        this.mode = 'edit';
      }
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async signIn(username, password) {
    this.loading = true;
    try {
      const { token, info } = await http.post('/users/signin', { username, password }, false);
      if (token) {
        auth.setToken(token);
      }
      if (info) {
        auth.setUserInfo(info);
      }
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async createUser() {
    this.loading = true;
    const data = snakecaseKeys(this.user);
    try {
      await http.post('/users', data);
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async updateUser(id) {
    this.loading = true;
    const data = snakecaseKeys(this.user);
    try {
      await http.put(`/users/${id}`, data);
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async deleteUser(id) {
    this.loading = true;
    try {
      const user = await http.delete(`/users/${id}/status`);
      if (user && user.id) {
        this.users = this.users.filter(usr => usr.id !== user.id);
      }
      return user;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }
}

const userStore = new UserStore();
export default userStore;
