import React, { Component } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditorPlus from '@emetworks/ckeditor5-build-classic-plus';
import auth from '../../utils/auth';

class ImageUploadAdapter {
  constructor(loader, url) {
    this.loader = loader;
    this.url = url;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        }),
    );
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());
    xhr.open('POST', this.url, true);
    xhr.responseType = 'json';
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `เกิดข้อผิดพลาด การอัพโหลดไฟล์ ${file.name} ไม่สำเร็จ`;

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const status = xhr.status;
      const response = xhr.response;
      if (status !== 200 || !response || response.error) {
        return reject(
          response && response.error
            ? response.error.message
            : genericErrorText,
        );
      }
      resolve({
        // default: response.url,
        default: response.data.path,
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    const data = new FormData();
    data.append('file', file);
    const token = auth.getToken();
    if (token) {
      this.xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    }
    this.xhr.send(data);
  }
}

export default class EnCKEditor extends Component {
  render() {
    const { data, onChange, imageUploadUrl } = this.props;
    const ImageUploadAdapterPlugin = editor => {
      editor.plugins.get('FileRepository').createUploadAdapter = function(
        loader,
      ) {
        return new ImageUploadAdapter(loader, imageUploadUrl);
      };
    };
    const config = {
      extraPlugins: [ImageUploadAdapterPlugin],
    };
    return (
      <CKEditor
        editor={ClassicEditorPlus}
        data={data}
        onChange={onChange}
        config={config}
      />
    );
  }
}
