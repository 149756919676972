import { extendObservable } from 'mobx';
import camelcaseKeys from 'camelcase-keys';
import http from '../utils/http';
// import snakecaseKeys from 'snakecase-keys';

export class ContactStore {
  constructor() {
    extendObservable(this, {
      loading: false,
      contact: [],
    });
  }

  setContactData(contact) {
    this.contact = camelcaseKeys(contact);
  }

  async loadContact(startDate, endDate) {
    this.loading = true;
    try {
      let dateTarget = ``;
      if(startDate || endDate){
        dateTarget = `?start_date=${startDate}&end_date=${endDate}`;
      }
      const response = await http.get(`/contactus${dateTarget}`);
      console.log(response)
      if (response) {
        this.setContactData(response);
      }
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }
}

const contactStore = new ContactStore();
export default contactStore;
