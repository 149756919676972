import React from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { observer, inject } from 'mobx-react';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Add from "@material-ui/icons/Add";
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
// import Visibility from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { Tooltip, InputAdornment } from '@material-ui/core';
import Swal from 'sweetalert2';
import moment from "moment";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import FormLabel from '@material-ui/core/FormLabel';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx';
import Input from "@material-ui/core/Input";
import Datetime from "react-datetime";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// core components
const styleInputDate = {
  fontWeight: '400',
  width: '100%',
  height: '36px',
  fontSize: '12px',
}

const styles = {
  ...buttonStyle,
  ...validationFormsStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  }
};

class Holiday extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      nameState: '',
      startDateState: '',
      endDateState: '',
      open: false,
      holidayId: null,
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.cancelDetele = this.cancelDetele.bind(this);
  }

  handleClickOpen = (id) => {
    if (id) {
      this.setState({ nameState: 'success' });
      this.setState({ startDateState: 'success' });
      this.setState({ endDateState: 'success' });
      this.setState({ holidayId: id });
      this.props.holiday.loadHoliday(id);
    } else {
      this.setState({ mode: 'create' });
      this.setState({ nameState: '' });
      this.setState({ startDateState: '' });
      this.setState({ endDateState: '' });
      this.setState({ holidayId: null });
      this.props.holiday.resetHoliday();
    }
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };


  async componentDidMount() {
    try {
      await this.props.holiday.loadHolidays();
    } catch (error) {
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block", marginTop: "-100px" }}
            title={`Error !`}
            onConfirm={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
            {`${error.message}`}
          </SweetAlert>
        )
      });
    }
  }

  onDeleteClick = id => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: async () => {
        return await this.props.holiday.deleteHoliday(id)
          .then(response => {
            if (response) { return response } else { throw response }
          })
          .catch(error => {
            Swal.showValidationMessage(error.message)
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        Swal.fire('Deleted!', '', 'success');
      }
    })
  };

  renderStartInput = (props) => {
    const { classes } = this.props;
    props.endAdornment = this.state.startDateState === 'error' ? <InputAdornment position="end">
      <Close className={classes.danger} />
    </InputAdornment> : '';
    return (
      <div>
        <Input
          style={styleInputDate}
          id={'startDate'}
          {...props}
        />
      </div>
    );
  }

  renderEndInput = (props) => {
    const { classes } = this.props;
    props.endAdornment = this.state.endDateState === 'error' ? <InputAdornment position="end">
      <Close className={classes.danger} />
    </InputAdornment> : '';
    return (
      <div>
        <Input
          // error={this.state.endDateState === 'error'}
          style={styleInputDate}
          id={'endDate'}
          {...props}
        />
      </div>
    );
  }

  validationHolidayeSetting() {
    let statusValidation = true;
    if (this.state.nameState === '' || this.state.nameState === 'error') {
      this.setState({ nameState: 'error' });
      statusValidation = false;
    }
    if (this.state.startDateState === '' || this.state.startDateState === 'error') {
      this.setState({ startDateState: 'error' });
      statusValidation = false;
    }
    if (this.state.endDateState === '' || this.state.endDateState === 'error') {
      this.setState({ endDateState: 'error' });
      statusValidation = false;
    }
    return statusValidation;
  }

  async updateHoliday() {
    try {
      let response;
      if (this.state.holidayId) {
        response = await this.props.holiday.updateHoliday(this.state.holidayId);
      } else {
        response = await this.props.holiday.createHoliday();
      }
      if (response) {
        this.setState({ open: false });
        await this.props.holiday.loadHolidays();
        this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Success !"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      } else {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block", marginTop: "-100px" }}
              title="Error !"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      }
    } catch (error) {
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block", marginTop: "-100px" }}
            title="Error !"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
            {`${error}`}
          </SweetAlert>
        )
      });
    }
  }

  cancelDetele() {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Cancelled"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
        </SweetAlert>
      )
    });
  }

  async confirmUpdateHoliday() {
    if (this.validationHolidayeSetting()) {
      const { holiday } = this.props.holiday;
      this.setState({
        alert: (
          <SweetAlert
            warning
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            confirmBtnText="Confirm"
            title="Are you sure?"
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.default
            }
            onConfirm={() => this.updateHoliday()}
            onCancel={() => this.cancelDetele()}
            focusCancelBtn
          >
            <form>
              <b>Name</b> : {holiday.name}<br />
              <b>Start Date</b> : {moment(holiday.startDate).format("DD/MM/YYYY")}<br />
              <b>End Date</b> : {moment(holiday.endDate).format("DD/MM/YYYY")}
            </form>
          </SweetAlert>
        )
      });
    }
  }
  handleStartDateChange = date => {
    if (moment(date).isValid()) {
      let { holiday } = this.props.holiday;
      if (holiday.endDate) {
        try {
          if (date.isSameOrBefore(holiday.endDate)) {
            this.setState({ startDateState: 'success' });
            this.setState({ endDateState: 'success' });
          } else {
            this.setState({ startDateState: 'error' });
            this.setState({ endDateState: 'error' });
          }
        } catch (error) {
          this.setState({ startDateState: 'error' });
          this.setState({ endDateState: 'error' });
        }
      }
      this.props.holiday.changeField('startDate', new Date(date));
    } else {
      this.setState({ startDateState: 'error' });
    }
  };
  handleEndDateChange = date => {
    if (moment(date).isValid()) {
      let { holiday } = this.props.holiday;
      if (holiday.startDate) {
        try {
          if (date.isSameOrAfter(holiday.startDate)) {
            this.setState({ startDateState: 'success' });
            this.setState({ endDateState: 'success' });
          } else {
            this.setState({ startDateState: 'error' });
            this.setState({ endDateState: 'error' });
          }
        } catch (error) {
          this.setState({ startDateState: 'error' });
          this.setState({ endDateState: 'error' });
        }
      }
      this.props.holiday.changeField('endDate', new Date(date));
    } else {
      this.setState({ endDateState: 'error' });
    }
  };

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    this.props.holiday.changeField(event.target.name, event.target.value);
    switch (type) {
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      default:
        break;
    }
  }

  async submitHoliday(item = null) {
    if (item) {
      await this.props.holiday.changeField('name', item.name);
      await this.props.holiday.changeField('startDate', item.startDate);
      await this.props.holiday.changeField('endDate', item.endDate);
    } else {
      await this.props.holiday.resetHoliday();
    }
    const { classes } = this.props;
    const { holiday } = this.props.holiday;
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          title=""
          style={{ display: "block", marginTop: "-100px" }}
          // onConfirm={e => this.addNewEvent(e, slotInfo)}
          onCancel={() => this.hideAlert()}
          confirmBtnText={'Save'}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          onConfirm={() => this.confirmUpdateHoliday(item.id)}
        >
          <form>
            <h4 className={classes.cardTitle} style={{ color: 'black' }}>
              {'Holiday Setting'}
            </h4>
            <GridContainer>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.labelHorizontal}>
                  Name
              </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <CustomInput
                  success={this.state.nameState === 'success'}
                  error={this.state.nameState === 'error'}
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event =>
                      this.change(event, 'name', 'length', 1),
                    type: 'text',
                    placeholder: `${holiday.name || ''}`,
                    // placeholder: `${holiday.name || ''}`,
                    name: 'name',
                    endAdornment:
                      this.state.nameState === 'error' ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                          undefined
                        ),
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.labelHorizontal}>
                  Start Date
              </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <div className={classes.contentDate}>
                  <Datetime
                    timeFormat={false}
                    inputProps={{
                      placeholder: "Start",
                    }}
                    value={holiday.startDate || ''}
                    onChange={this.handleStartDateChange}
                    renderInput={this.renderStartInput} />
                </div>

              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.labelHorizontal}>
                  End Date
              </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <div className={classes.contentDate}>
                  <Datetime
                    timeFormat={false}
                    inputProps={{
                      placeholder: "End",
                    }}
                    value={holiday.endDate}
                    onChange={this.handleEndDateChange}
                    renderInput={this.renderEndInput} />
                </div>
              </GridItem>
            </GridContainer>

          </form>
        </SweetAlert>
      )
    });
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  render() {
    const { classes } = this.props;
    let { holidays, holiday } = this.props.holiday;
    let dataHoliday = holidays.map((prop, key) => {
      return {
        id: prop.id,
        no: key,
        name: prop.name,
        startDate: moment(prop.startDate).format("DD/MM/YYYY"),
        endDate: moment(prop.endDate).format("DD/MM/YYYY"),
        actions: (
          <div className="actions-center">
            <Tooltip title="Edit Holiday" placement="top">
              <Button
                justIcon
                round
                onClick={() => {
                  let obj = dataHoliday.find(o => o.no === key);
                  this.handleClickOpen(obj.id)
                }}
                color="warning"
                className="edit">
                <Edit />
              </Button>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;
            <Tooltip title="Delete" placement="top">
              <Button
                justIcon
                round
                onClick={() => {
                  let obj = dataHoliday.find(o => o.no === key);
                  this.onDeleteClick(obj.id)
                }}
                color="danger"
                className="remove">
                <Close />
              </Button>
            </Tooltip>
          </div>
        )
      };
    });
    return (
      <GridContainer>
        {this.state.alert}
        <Dialog
          fullWidth={true}
          maxWidth='sm'
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Holiday Setting</DialogTitle>
          <DialogContent>
            <form>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    Name
              </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8}>
                  <CustomInput
                    success={this.state.nameState === 'success'}
                    error={this.state.nameState === 'error'}
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event =>
                        this.change(event, 'name', 'length', 1),
                      type: 'text',
                      value: `${holiday.name || ''}`,
                      name: 'name',
                      endAdornment:
                        this.state.nameState === 'error' ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                            undefined
                          ),
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    Start Date
              </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8}>
                  <div className={classes.contentDate}>
                    <Datetime
                      locale="en"
                      timeFormat={false}
                      inputProps={{
                        placeholder: "Start",
                      }}
                      value={holiday.startDate ? moment(holiday.startDate).format("DD/MM/YYYY") : ''}
                      onChange={this.handleStartDateChange}
                      renderInput={this.renderStartInput} />
                  </div>

                </GridItem>
              </GridContainer>
              <GridContainer style={{ marginBottom: '300px' }}>
                <GridItem xs={12} sm={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    End Date
              </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8}>
                  <div className={classes.contentDate}>
                    <Datetime
                      locale="en"
                      timeFormat={false}
                      inputProps={{
                        placeholder: "End",
                      }}
                      value={holiday.endDate ? moment(holiday.endDate).format("DD/MM/YYYY") : ''}
                      onChange={this.handleEndDateChange}
                      renderInput={this.renderEndInput} />
                  </div>
                </GridItem>
              </GridContainer>

            </form>

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="default">
              Cancel
            </Button>
            <Button onClick={() => this.confirmUpdateHoliday()} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Person />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Holiday List</h4>
              <CardIcon onClick={() => { this.handleClickOpen() }} color="info" style={{ float: 'right', borderRadius: '35px', cursor: 'pointer' }}>
                <Add /> <div style={{ float: 'right', paddingTop: '7px', fontWeight: 'bold', paddingRight: '6px' }}>Create Holiday</div>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={dataHoliday}
                filterable
                columns={[
                  {
                    Header: "ID",
                    accessor: "id",
                    width: 80
                  },
                  {
                    Header: "name",
                    accessor: "name"
                  },
                  {
                    Header: "Start Date",
                    accessor: "startDate"
                  },
                  {
                    Header: "End Date",
                    accessor: "endDate"
                  },
                  {
                    Header: "Actions",
                    headerStyle: { textAlign: 'center' },
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

Holiday.propTypes = {
  classes: PropTypes.object
};

export const page = inject('holiday')(observer(Holiday));
export default withStyles(styles, { withTheme: true })(page);