import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
// @material-ui/core components
import Datetime from "react-datetime";
import withStyles from '@material-ui/core/styles/withStyles';
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
// nodejs library that concatenates classes
// import classNames from "classnames";
// material ui icons
// import Check from "@material-ui/icons/Check";
import SweetAlert from "react-bootstrap-sweetalert";
import Close from '@material-ui/icons/Close';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import classnames from 'classnames';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardText from 'components/Card/CardText.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
// import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from 'components/Card/CardBody.jsx';
import Swal from 'sweetalert2';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx';
import Input from "@material-ui/core/Input";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

const styleInputDate = {
  fontWeight: '400',
  width: '100%',
  height: '36px',
  fontSize: '12px',
}
const styles = {
  ...validationFormsStyle,
  ...buttonStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};


class PriceSettingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priceState: '',
      pricePerPersonState: '',
      dayPriceState: '',
      fridayPriceState: '',
      saturdayPriceState: '',
      sundayPriceState: '',
      holidayPriceState: '',
      startDateState: '',
      endDateState: '',
      alert: null,
    };
    this.submitClick = this.submitClick.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  async componentDidMount() {
    let id = this.props.match.params.id;
    if (id) {
      try {
        await this.props.house.fetchHouse(id);
        await this.props.priceSetting.initPriceSetting();
        await this.props.priceSetting.changeField('houseId', +id);
      } catch (error) {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block", marginTop: "-100px" }}
              title={`Error !`}
              onConfirm={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
              {`${error.message}`}
            </SweetAlert>
          )
        });
      }
    }
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }


  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    this.props.priceSetting.changeField(event.target.name, event.target.value);
    switch (type) {
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({ [stateName + 'State']: 'error' });
        }
        break;
      default:
        break;
    }
  }

  printError(code) {
    let message = 'ไม่สามารถบันทึกข้อมูลได้';
    switch (code) {
      case 'validation_failed':
        message = 'กรุณากรอกข้อมูลให้ถูกต้องครบถ้วน';
        break;
      case 'data_not_found':
        message = 'ไม่พบข้อมูล';
        break;
      default:
        break;
    }
    return message;
  }

  async submitClick() {
    if (this.validationPriceSetting()) {
      Swal.fire({
        title: 'บันทึก Price Setting',
        text: '',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: async () => {
          return await this.props.priceSetting.createPriceSetting()
            .then(response => {
              if (response) {
                return response
              } else {
                throw response
              }
            })
            .catch(error => {
              Swal.showValidationMessage(error)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.value && result.value.house_id) {
          this.props.history.push(`/admin/house/price-calendar/${result.value.house_id}`);
          // Swal.fire('Submit Price Setting!', '', 'success');
        }
      })
    }
  }

  validationPriceSetting() {
    let statusValidation = true;
    if (this.state.priceState === '' || this.state.priceState === 'error') {
      this.setState({ priceState: 'error' });
      statusValidation = false;
    }
    if (this.state.pricePerPersonState === '' || this.state.pricePerPersonState === 'error') {
      this.setState({ pricePerPersonState: 'error' });
      statusValidation = false;
    }
    if (this.state.dayPriceState === '' || this.state.dayPriceState === 'error') {
      this.setState({ dayPriceState: 'error' });
      statusValidation = false;
    }
    if (this.state.fridayPriceState === '' || this.state.fridayPriceState === 'error') {
      this.setState({ fridayPriceState: 'error' });
      statusValidation = false;
    }
    if (this.state.saturdayPriceState === '' || this.state.saturdayPriceState === 'error') {
      this.setState({ saturdayPriceState: 'error' });
      statusValidation = false;
    }
    if (this.state.sundayPriceState === '' || this.state.sundayPriceState === 'error') {
      this.setState({ sundayPriceState: 'error' });
      statusValidation = false;
    }
    if (this.state.holidayPriceState === '' || this.state.holidayPriceState === 'error') {
      this.setState({ holidayPriceState: 'error' });
      statusValidation = false;
    }

    if (this.state.startDateState === '' || this.state.startDateState === 'error') {
      this.setState({ startDateState: 'error' });
      statusValidation = false;
    }
    if (this.state.endDateState === '' || this.state.endDateState === 'error') {
      this.setState({ endDateState: 'error' });
      statusValidation = false;
    }
    return statusValidation;
  }
  handleStartDateChange = date => {
    let { priceSetting } = this.props.priceSetting;
    if (priceSetting.endDate) {
      if (date.isSameOrBefore(priceSetting.endDate)) {
        this.setState({ startDateState: 'success' });
        this.setState({ endDateState: 'success' });
      } else {
        this.setState({ startDateState: 'error' });
        this.setState({ endDateState: 'error' });
      }
    }
    this.props.priceSetting.changeField('startDate', new Date(date));
  };
  handleEndDateChange = date => {
    let { priceSetting } = this.props.priceSetting;
    if (priceSetting.startDate) {
      if (date.isSameOrAfter(priceSetting.startDate)) {
        this.setState({ startDateState: 'success' });
        this.setState({ endDateState: 'success' });
      } else {
        this.setState({ startDateState: 'error' });
        this.setState({ endDateState: 'error' });
      }
    }
    this.props.priceSetting.changeField('endDate', new Date(date));
  };

  renderStartInput = (props) => {
    const { classes } = this.props;
    props.endAdornment = this.state.startDateState === 'error' ? <InputAdornment position="end">
      <Close className={classes.danger} />
    </InputAdornment> : '';
    return (
      <div>
        <Input
          // error={this.state.startDateState === 'error'}
          style={styleInputDate}
          id={'startDate'}
          {...props}
        />
      </div>
    );
  }

  renderEndInput = (props) => {
    const { classes } = this.props;
    props.endAdornment = this.state.endDateState === 'error' ? <InputAdornment position="end">
      <Close className={classes.danger} />
    </InputAdornment> : '';
    return (
      <div>
        <Input
          // error={this.state.endDateState === 'error'}
          style={styleInputDate}
          id={'endDate'}
          {...props}
        />
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { house } = this.props.house;
    let { priceSetting } = this.props.priceSetting;
    return (
      <GridContainer>
        {this.state.alert}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" text>
              <CardText color="primary">
                <h4 className={classes.cardTitle}>
                  {'Price Setting'}
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                {/* house name */}
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      House
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <CustomInput
                      id="house"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'house', 'length', 3),
                        type: 'text',
                        value: house.name || '',
                        name: 'house',
                        disabled: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                {/* period */}
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Period
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <div className={classes.contentDate}>
                      <Datetime
                        locale="en"
                        timeFormat={false}
                        inputProps={{
                          placeholder: "Start",
                        }}
                        value={priceSetting.startDate}
                        onChange={this.handleStartDateChange}
                        renderInput={this.renderStartInput} />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <div className={classes.contentDate}>
                      <Datetime
                        locale="en"
                        timeFormat={false}
                        inputProps={{
                          placeholder: "End",
                        }}
                        value={priceSetting.endDate}
                        onChange={this.handleEndDateChange}
                        renderInput={this.renderEndInput} />
                    </div>
                  </GridItem>
                </GridContainer>
                {/* price per person */}
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Price Per Person
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={10} sm={8}>
                    <CustomInput
                      // error={this.state.pricePerPersonState === 'error'}
                      id="pricePerPerson"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'pricePerPerson', 'length', 1),
                        type: 'number',
                        value: `${priceSetting.pricePerPerson || ''}`,
                        name: 'pricePerPerson',
                        endAdornment:
                          this.state.pricePerPersonState === 'error' ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                              undefined
                            ),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2} sm={2}>
                    <FormLabel
                      className={classnames(classes.labelHorizontal, classes.labelHorizontalLeft)} >
                      Bath
                    </FormLabel>
                  </GridItem>
                </GridContainer>
                {/* default price */}
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Default Price
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={10} sm={8}>
                    <CustomInput
                      success={this.state.priceState === 'success'}
                      error={this.state.priceState === 'error'}
                      id="price"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'price', 'length', 1),
                        type: 'number',
                        value: `${priceSetting.price || ''}`,
                        name: 'price',
                        endAdornment:
                          this.state.priceState === 'error' ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                              undefined
                            ),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2} sm={2}>
                    <FormLabel
                      className={classnames(classes.labelHorizontal, classes.labelHorizontalLeft)}>
                      Bath
                    </FormLabel>
                  </GridItem>
                </GridContainer>
                {/* price (special) */}
                <GridContainer>
                  <GridItem xs={2} sm={2} md={2}></GridItem>
                  <GridItem xs={8} sm={8} md={8}>
                    <Card style={{ background: '#fbfbfb' }}>
                      <CardHeader color="primary" text>
                        <CardText color="primary">
                          <h4 className={classes.cardTitle}>
                            {'Price Special'}
                          </h4>
                        </CardText>
                      </CardHeader>
                      <CardBody>
                        {/* price day */}
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel className={classes.labelHorizontal}>
                              Day
                    </FormLabel>
                          </GridItem>
                          <GridItem xs={10} sm={8}>
                            <CustomInput
                              success={this.state.dayPriceState === 'success'}
                              error={this.state.dayPriceState === 'error'}
                              id="dayPrice"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                onChange: event =>
                                  this.change(event, 'dayPrice', 'length', 1),
                                type: 'number',
                                value: `${priceSetting.dayPrice || ''}`,
                                name: 'dayPrice',
                                endAdornment:
                                  this.state.dayPriceState === 'error' ? (
                                    <InputAdornment position="end">
                                      <Close className={classes.danger} />
                                    </InputAdornment>
                                  ) : (
                                      undefined
                                    ),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={2} sm={2}>
                            <FormLabel
                              className={classnames(classes.labelHorizontal, classes.labelHorizontalLeft)}>
                              Bath
                    </FormLabel>
                          </GridItem>
                        </GridContainer>
                        {/* price holiday */}
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel className={classes.labelHorizontal}>
                              Holiday
                    </FormLabel>
                          </GridItem>
                          <GridItem xs={10} sm={8}>
                            <CustomInput
                              success={this.state.holidayPriceState === 'success'}
                              error={this.state.holidayPriceState === 'error'}
                              id="holidayPrice"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                onChange: event =>
                                  this.change(event, 'holidayPrice', 'length', 1),
                                type: 'number',
                                value: `${priceSetting.holidayPrice || ''}`,
                                name: 'holidayPrice',
                                endAdornment:
                                  this.state.holidayPriceState === 'error' ? (
                                    <InputAdornment position="end">
                                      <Close className={classes.danger} />
                                    </InputAdornment>
                                  ) : (
                                      undefined
                                    ),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={2} sm={2}>
                            <FormLabel
                              className={classnames(classes.labelHorizontal, classes.labelHorizontalLeft)}>
                              Bath
                    </FormLabel>
                          </GridItem>
                        </GridContainer>
                        {/* price friday */}
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel className={classes.labelHorizontal}>
                              Friday
                    </FormLabel>
                          </GridItem>
                          <GridItem xs={10} sm={8}>
                            <CustomInput
                              success={this.state.fridayPriceState === 'success'}
                              error={this.state.fridayPriceState === 'error'}
                              id="fridayPrice"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                onChange: event =>
                                  this.change(event, 'fridayPrice', 'length', 1),
                                type: 'number',
                                value: `${priceSetting.fridayPrice || ''}`,
                                name: 'fridayPrice',
                                endAdornment:
                                  this.state.fridayPriceState === 'error' ? (
                                    <InputAdornment position="end">
                                      <Close className={classes.danger} />
                                    </InputAdornment>
                                  ) : (
                                      undefined
                                    ),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={2} sm={2}>
                            <FormLabel
                              className={classnames(classes.labelHorizontal, classes.labelHorizontalLeft)}>
                              Bath
                    </FormLabel>
                          </GridItem>
                        </GridContainer>
                        {/* price saturday */}
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel className={classes.labelHorizontal}>
                              Saturday
                    </FormLabel>
                          </GridItem>
                          <GridItem xs={10} sm={8}>
                            <CustomInput
                              success={this.state.saturdayPriceState === 'success'}
                              error={this.state.saturdayPriceState === 'error'}
                              id="saturdayPrice"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                onChange: event =>
                                  this.change(event, 'saturdayPrice', 'length', 1),
                                type: 'number',
                                value: `${priceSetting.saturdayPrice || ''}`,
                                name: 'saturdayPrice',
                                endAdornment:
                                  this.state.saturdayPriceState === 'error' ? (
                                    <InputAdornment position="end">
                                      <Close className={classes.danger} />
                                    </InputAdornment>
                                  ) : (
                                      undefined
                                    ),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={2} sm={2}>
                            <FormLabel
                              className={classnames(classes.labelHorizontal, classes.labelHorizontalLeft)}>
                              Bath
                    </FormLabel>
                          </GridItem>
                        </GridContainer>
                        {/* price sunday */}
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel className={classes.labelHorizontal}>
                              Sunday
                    </FormLabel>
                          </GridItem>
                          <GridItem xs={10} sm={8}>
                            <CustomInput
                              success={this.state.sundayPriceState === 'success'}
                              error={this.state.sundayPriceState === 'error'}
                              id="sundayPrice"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                onChange: event =>
                                  this.change(event, 'sundayPrice', 'length', 1),
                                type: 'number',
                                value: `${priceSetting.sundayPrice || ''}`,
                                name: 'sundayPrice',
                                endAdornment:
                                  this.state.sundayPriceState === 'error' ? (
                                    <InputAdornment position="end">
                                      <Close className={classes.danger} />
                                    </InputAdornment>
                                  ) : (
                                      undefined
                                    ),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={2} sm={2}>
                            <FormLabel
                              className={classnames(classes.labelHorizontal, classes.labelHorizontalLeft)}>
                              Bath
                    </FormLabel>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>

              </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button color="primary" onClick={this.submitClick}>
                {'Submit'}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
PriceSettingPage.propTypes = {
  classes: PropTypes.object,
};



export const page = inject('priceSetting', 'house')(observer(PriceSettingPage));
export default withStyles(styles)(page);
