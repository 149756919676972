import React from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { observer, inject } from 'mobx-react';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Add from "@material-ui/icons/Add";
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
// import Visibility from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { Tooltip } from '@material-ui/core';
import Swal from 'sweetalert2';
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

const styles = {
  ...buttonStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
    };
    this.hideAlert = this.hideAlert.bind(this);
  }
  async componentDidMount() {
    try {
      await this.props.user.loadUser();
    } catch (error) {
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block", marginTop: "-100px" }}
            title={`Error !`}
            onConfirm={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
           {`${error.message}`}
          </SweetAlert>
        )
      });
    }
  }

  onDeleteClick = id => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: async () => {
        return await this.props.user.deleteUser(id)
          .then(response => {
            if (response) { return response } else { throw response }
          })
          .catch(error => {
            Swal.showValidationMessage(error.message)
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        Swal.fire('Deleted!', '', 'success');
      }
    })
  };

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  render() {
    const { classes } = this.props;
    let { users } = this.props.user;
    let dataUser = users.map((prop, key) => {
      return {
        id: prop.id,
        no: key,
        username: prop.username,
        fullname: `${prop.firstname} ${prop.lastname} ${prop.nickname ? `(${prop.nickname})` : ''}`,
        mobile1: prop.mobile_one,
        mobile2: prop.mobile_two,
        role: prop.role,
        status: prop.status,
        actions: (
          <div className="actions-center">
            <Tooltip title="Edit User" placement="top">
              <Button
                justIcon
                round
                // simple
                onClick={() => {
                  let obj = dataUser.find(o => o.no === key);
                  this.props.history.push(`/admin/user/${obj.id}/edit`)
                }}
                color="warning"
                className="edit">
                <Edit />
              </Button>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;
            <Tooltip title="Delete" placement="top">
              <Button
                justIcon
                round
                // simple
                onClick={() => {
                  let obj = dataUser.find(o => o.no === key);
                  this.onDeleteClick(obj.id)
                }}
                color="danger"
                className="remove">
                <Close />
              </Button>
            </Tooltip>
          </div>
        )
      };
    });

    return (
      <GridContainer>
        {this.state.alert}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Person />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>User List</h4>
              <CardIcon onClick={() => { this.props.history.push(`/admin/user/create`) }} color="info" style={{ float: 'right', borderRadius: '35px', cursor: 'pointer' }}>
                <Add /> <div style={{ float: 'right', paddingTop: '7px', fontWeight: 'bold', paddingRight: '6px' }}>Create User</div>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={dataUser}
                filterable
                columns={[
                  {
                    Header: "ID",
                    accessor: "id",
                    width: 80
                  },
                  {
                    Header: "Username",
                    accessor: "username"
                  },
                  {
                    Header: "Fullname",
                    accessor: "fullname"
                  },
                  {
                    Header: "Mobile 1",
                    accessor: "mobile1"
                  },
                  {
                    Header: "Mobile 2",
                    accessor: "mobile2"
                  },
                  {
                    Header: "Role",
                    accessor: "role"
                  },
                  {
                    Header: "Status",
                    accessor: "status"
                  },
                  {
                    Header: "Actions",
                    headerStyle: { textAlign: 'center' },
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

User.propTypes = {
  classes: PropTypes.object
};

export const page = inject('user')(observer(User));
export default withStyles(styles, { withTheme: true })(page);