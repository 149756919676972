import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import withStyles from '@material-ui/core/styles/withStyles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';

// material ui icons
import CloseIcon from '@material-ui/icons/Close';
import BankIcon from '@material-ui/icons/AccountBalance';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// style for this view
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';

import Swal from 'sweetalert2';

// Util
import validateControl, { VALIDATE_TYPES } from '../../utils/validateControl';

const defaultState = 'success';
class BankAccount extends Component {
  initBankAccount = {
    bankName: '',
    accountNo: '',
    accountName: '',
    branch: '',
    status: '',
  };

  defaultHouseState = {
    bankName: defaultState,
    accountNo: defaultState,
    accountName: defaultState,
    branch: defaultState,
    status: defaultState,
  };

  constructor(props) {
    super(props);
    this.state = {
      bankState: this.initBankAccount,
    };
  }

  componentDidMount = async () => {
    try {
      const id = this.props.match.params.id;
      if (id) {
        this.setState({
          bankState: this.defaultHouseState,
        });
        await this.props.bankAccount.fetchBankAccount(id);
      } else {
        this.props.bankAccount.resetBankAccount();
        this.props.history.replace(`/admin/bank-account/create`);
      }
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: error.message,
      });
      this.props.history.replace(`/admin/bank-account/create`);
    }
  };

  ///////////// Render
  renderStatusItem = () => {
    const { classes } = this.props;
    const status = ['ACTIVE', 'INACTIVE'];

    return status.map((status, i) => {
      return (
        <MenuItem
          key={i}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={status}>
          {status}
        </MenuItem>
      );
    });
  };

  ///////////// Validate

  validationForm = () => {
    const currentBankState = this.state.bankState;
    let statusValidation = true;

    for (const key in currentBankState) {
      if (currentBankState.hasOwnProperty(key)) {
        if (
          currentBankState[key] === '' ||
          currentBankState[key] !== 'success'
        ) {
          currentBankState[key] = validateControl.getErrorText(
            VALIDATE_TYPES.EMPTY,
          );
          statusValidation = false;
        }
      }
    }

    this.setState({ bankState: currentBankState });

    return statusValidation;
  };

  ///////////// Text Event

  onTextFieldChange = (value, stateName, types, maxLength) => {
    const currentBankState = this.state.bankState;
    const result = validateControl.validate(value, types, maxLength);

    if (result.length > 0) {
      currentBankState[stateName] = validateControl.getErrorText(
        result[0],
        maxLength,
      );
    } else {
      currentBankState[stateName] = 'success';
    }

    this.props.bankAccount.setBankAccountProp(stateName, value);

    this.setState({
      bankState: currentBankState,
    });
  };

  ////////////// Submit

  submitClick = async () => {
    try {
      if (this.validationForm()) {
        const bankAccount = this.props.bankAccount.getBankAccountJS();

        if (bankAccount.status === 'ACTIVE') {
          const hasBankActive = await this.props.bankAccount.validateHasBankActive();

          if (hasBankActive) {
            Swal.fire({
              type: 'warning',
              title: 'บันทึก',
              text:
                'ไม่สามารถทำรายการได้ เนื่องจากสามารถมีบัญชีที่ Active ได้เพียงบัญชีเดียวเท่านั้น',
            });

            return;
          }
        }

        const result = await this.props.bankAccount.saveBankAccount();

        if (result) {
          Swal.fire({
            type: 'success',
            title: 'บันทึก',
            text: 'การบันทึกข้อมูลสำเร็จ',
          }).then(r => {
            if (r.value) {
              this.props.history.push(`/admin/bank-account/${result.id}/edit`);
            }
          });
        } else {
          Swal.fire({
            type: 'error',
            title: 'บันทึก',
            text: 'การบันทึกข้อมูลไม่สำเร็จ',
          });
        }
      }
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: 'บันทึก',
        text: 'บันทึกไม่สำเร็จ ระบบผิดพลาด',
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { mode } = this.props.bankAccount;
    const bankAccount = this.props.bankAccount.getBankAccountJS();
    const { bankState } = this.state;

    return (
      <React.Fragment>
        {/* Name, Owner, Status */}
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <BankIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>บัญชีธนาคาร</h4>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        ชื่อธนาคาร
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <CustomInput
                        id="bankName"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        success={bankState.bankName === 'success'}
                        error={bankState.bankName !== 'success'}
                        helperText={
                          bankState.bankName === 'success'
                            ? ''
                            : bankState.bankName
                        }
                        inputProps={{
                          onChange: event =>
                            this.onTextFieldChange(
                              event.target.value,
                              'bankName',
                              [VALIDATE_TYPES.EMPTY, VALIDATE_TYPES.MAX_LENGTH],
                              50,
                            ),
                          type: 'text',
                          value: `${bankAccount.bankName || ''}`,
                          name: 'bankName',
                          endAdornment:
                            bankState.bankName === 'success' ||
                            bankState.bankName === '' ? (
                              undefined
                            ) : (
                              <InputAdornment position="end">
                                <CloseIcon className={classes.danger} />
                              </InputAdornment>
                            ),
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        สาขาธนาคาร
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <CustomInput
                        id="branch"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        success={bankState.branch === 'success'}
                        error={bankState.branch !== 'success'}
                        helperText={
                          bankState.branch === 'success' ? '' : bankState.branch
                        }
                        inputProps={{
                          onChange: event =>
                            this.onTextFieldChange(
                              event.target.value,
                              'branch',
                              [VALIDATE_TYPES.EMPTY, VALIDATE_TYPES.MAX_LENGTH],
                              50,
                            ),
                          type: 'text',
                          value: `${bankAccount.branch || ''}`,
                          name: 'branch',
                          endAdornment:
                            bankState.branch === 'success' ||
                            bankState.branch === '' ? (
                              undefined
                            ) : (
                              <InputAdornment position="end">
                                <CloseIcon className={classes.danger} />
                              </InputAdornment>
                            ),
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        เลขบัญชี
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <CustomInput
                        id="accountNo"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        success={bankState.accountNo === 'success'}
                        error={bankState.accountNo !== 'success'}
                        helperText={
                          bankState.accountNo === 'success'
                            ? ''
                            : bankState.accountNo
                        }
                        inputProps={{
                          onChange: event =>
                            this.onTextFieldChange(
                              event.target.value,
                              'accountNo',
                              [VALIDATE_TYPES.EMPTY, VALIDATE_TYPES.MAX_LENGTH],
                              30,
                            ),
                          type: 'text',
                          value: `${bankAccount.accountNo || ''}`,
                          name: 'accountNo',
                          endAdornment:
                            bankState.accountNo === 'success' ||
                            bankState.accountNo === '' ? (
                              undefined
                            ) : (
                              <InputAdornment position="end">
                                <CloseIcon className={classes.danger} />
                              </InputAdornment>
                            ),
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        ชื่อบัญชี
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <CustomInput
                        id="accountName"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        success={bankState.accountName === 'success'}
                        error={bankState.accountName !== 'success'}
                        helperText={
                          bankState.accountName === 'success'
                            ? ''
                            : bankState.accountName
                        }
                        inputProps={{
                          onChange: event =>
                            this.onTextFieldChange(
                              event.target.value,
                              'accountName',
                              [VALIDATE_TYPES.EMPTY, VALIDATE_TYPES.MAX_LENGTH],
                              50,
                            ),
                          type: 'text',
                          value: `${bankAccount.accountName || ''}`,
                          name: 'accountName',
                          endAdornment:
                            bankState.accountName === 'success' ||
                            bankState.accountName === '' ? (
                              undefined
                            ) : (
                              <InputAdornment position="end">
                                <CloseIcon className={classes.danger} />
                              </InputAdornment>
                            ),
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel
                        style={{
                          paddingTop: '38px',
                        }}
                        className={classes.labelHorizontal}>
                        สถานะ
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}>
                        <Select
                          style={{
                            paddingTop: '28px',
                          }}
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={bankAccount.status}
                          onChange={event =>
                            this.onTextFieldChange(
                              event.target.value,
                              'status',
                              [VALIDATE_TYPES.EMPTY],
                            )
                          }>
                          {this.renderStatusItem()}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="primary" onClick={this.submitClick}>
                  {mode === 'edit' ? 'แก้ไข' : 'บันทึก'}
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

BankAccount.propTypes = {
  classes: PropTypes.object,
};

export const page = inject('bankAccount')(observer(BankAccount));
export default withStyles(
  validationFormsStyle,
  sweetAlertStyle,
  extendedFormsStyle,
)(page);
