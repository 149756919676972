import { extendObservable } from 'mobx';
import camelcaseKeys from 'camelcase-keys';
import http from '../utils/http';
// import snakecaseKeys from 'snakecase-keys';

export class BookingStore {
  constructor() {
    extendObservable(this, {
      loading: false,
      bookings: [],
      mode: 'create',
    });
  }

  setUsers(bookings) {
    this.bookings = camelcaseKeys(bookings);
  }

  async statusBooking(id,status,houseId) {
    try {
      const data = {
        status
      }
      let url = `/bookings/${id}/status`;
      let response = await http.put(url, data);
      if(response){
        await this.loadBooking(houseId || null);
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async createTempBooking(id,start,end) {
    try {
      start.setHours(start.getHours()+12);
      end.setTime(end.getTime() + (11*60*60*1000) + (59*60*1000));
      const data = {
        start_date : start,
        end_date : end,
        house_id : id
      }
      let url = `/bookings/temp`;
      let response = await http.post(url, data);
      if(response){
        await this.loadBooking(id);
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async closeBooking(id,start,end) {
    try {
      start.setHours(start.getHours()+12);
      end.setTime(end.getTime() + (11*60*60*1000) + (59*60*1000));
      const data = {
        start_date : start,
        end_date : end,
        house_id : id
      }
      let url = `/bookings/close`;
      let response = await http.post(url, data);
      if(response){
        await this.loadBooking(id);
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async loadBooking(id) {
    this.loading = true;
    try {
      const url = id ? `/search?house_id=${id}&show_house=true&show_payment=true&limit=1000` : '?show_house=true&show_payment=true&limit=1000'
      const response = await http.get(`/bookings${url}`);
      if (response) {
        if(id){
          this.setUsers(response.data);
        }else{
          this.setUsers(response);
        }
      }
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }
}

const bookingStore = new BookingStore();
export default bookingStore;
