import { extendObservable } from 'mobx';
// import config from 'config';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import http from '../utils/http';

const initialData = {
  startDate: '',
  endDate: '',
  name: '',
};

export class HolidayStore {
  constructor() {
    extendObservable(this, {
      loading: false,
      holidays: [],
      holiday: initialData,
    });
  }

  resetHoliday() {
    this.holiday = initialData;
  }

  setHoliday(holiday) {
    this.holiday = camelcaseKeys(holiday);
  }

  changeField(task, data) {
    this.holiday[task] = data;
  }

  async loadHolidays() {
    this.loading = true;
    try {
      const holidays = await http.get('/holidays');
      this.holidays = camelcaseKeys(holidays);
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async loadHoliday(id) {
    this.loading = true;
    try {
      const holidays = await http.get(`/holidays/${id}`);
      if (holidays) {
        this.setHoliday(holidays);
      }
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async createHoliday() {
    this.loading = true;
    const data = snakecaseKeys(this.holiday);
    try {
      const response = await http.post('/holidays', data);
      return response
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async updateHoliday(id) {
    this.loading = true;
    const data = snakecaseKeys(this.holiday);
    try {
      const response = await http.put(`/holidays/${id}`, data);
      return response
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async deleteHoliday(id) {
    this.loading = true;
    try {
      const holiday = await http.delete(`/holidays/${id}`);
      if (holiday) {
        this.holidays = this.holidays.filter(val => val.id !== id);
      }
      return holiday;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }
}

const holidayStore = new HolidayStore();
export default holidayStore;
