import axios from 'axios';
import Swal from 'sweetalert2';
import config from 'config';
import auth from './auth';
import history from './history';
import ErrorMessages from '../ErrorMessages';

const HTTP_METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
};

class HttpClient {
  constructor() {
    this._axios = axios.create({
      baseURL: config.api.url,
    });
  }

  setAuthorization(config) {
    if (!config.headers) config.headers = {};
    const token = auth.getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  async doRequest(method, uri, query, data, config, setAuthorization) {
    const _config = config || {};
    if (query) _config.params = query;
    if (setAuthorization) this.setAuthorization(_config);
    try {
      let response;
      switch (method) {
        case HTTP_METHOD.GET:
          response = await this._axios.get(uri, _config);
          break;
        case HTTP_METHOD.POST:
          response = await this._axios.post(uri, data, _config);
          break;
        case HTTP_METHOD.PUT:
          response = await this._axios.put(uri, data, _config);
          break;
        case HTTP_METHOD.DELETE:
          response = await this._axios.delete(uri, _config);
          break;
        default: console.log('Unsupport http method');
      }
      return response.data;
    } catch (error) {
      let message;
      if (error.response) {
        const { data, status } = error.response;
        console.log('Response error -> status:', status, ', data:', data);
        message = ErrorMessages[data.code];
        if (status === 401 && setAuthorization && data.code === 'token_invalid') {
          Swal.fire({
            type: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: message,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ตกลง',
          }).then((result) => {
            if (result.value) {
              auth.clear();
              history.replace('/auth/login-page');
              window.location.reload();
            }
          });
          return;
        }
      } else if (error.request) {
        console.log(error.request);
        message = 'ไม่มีการตอบสนองจากเซิฟเวอร์';
      } else {
        console.log(error.message);
      }
      throw new Error(message || 'เกิดข้อผิดพลาด');
    }
  }

  async get(uri, query, setAuthorization = true) {
    return this.doRequest(
      HTTP_METHOD.GET,
      uri,
      query,
      null,
      null,
      setAuthorization,
    );
  }

  async post(uri, data, setAuthorization = true) {
    return this.doRequest(
      HTTP_METHOD.POST,
      uri,
      null,
      data,
      null,
      setAuthorization,
    );
  }

  async put(uri, data, setAuthorization = true) {
    return this.doRequest(
      HTTP_METHOD.PUT,
      uri,
      null,
      data,
      null,
      setAuthorization,
    );
  }

  async delete(uri, query, setAuthorization = true) {
    return this.doRequest(
      HTTP_METHOD.DELETE,
      uri,
      query,
      null,
      null,
      setAuthorization,
    );
  }

  async postMultipartForm(uri, data, setAuthorization = true) {
    return this.doRequest(
      HTTP_METHOD.POST,
      uri,
      null,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      },
      setAuthorization,
    );
  }

  async postFormUrlEncoded(uri, data, setAuthorization = true) {
    return this.doRequest(
      HTTP_METHOD.POST,
      uri,
      null,
      data,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      },
      setAuthorization,
    );
  }
}

const httpClient = new HttpClient();
export default httpClient;
