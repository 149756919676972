import storage from 'store';
import base64 from 'base-64';
import utf8 from 'utf8';

export const AuthKeys = {
  TOKEN: 'TOKEN',
  USER_INFO: 'USER_INFO',
};

class Auth {
  setToken(value) {
    storage.set(AuthKeys.TOKEN, value);
  }

  getToken() {
    return storage.get(AuthKeys.TOKEN);
  }

  setUserInfo(value) {
    const bytes = utf8.encode(JSON.stringify(value));
    storage.set(AuthKeys.USER_INFO, base64.encode(bytes));
  }

  getUserInfo() {
    const bytes = base64.decode(utf8.decode(storage.get(AuthKeys.USER_INFO) || ''))
    let data ;
    try {
      data = JSON.parse(bytes)
      data.firstname = utf8.decode(data.firstname)
      data.lastname = utf8.decode(data.lastname)
      data.nickname = utf8.decode(data.nickname)
      data.username = utf8.decode(data.username)
    } catch (error) {
      data = null
    }
    return data
  }

  getUserRole() {
    let userInfo = this.getUserInfo();
    let role = 'user';
    if (userInfo) {
      try {
        role = userInfo.role;
      } catch (error) {
        role = 'user'
      }
    }
    return role
  }

  isAuthenticated() {
    const token = this.getToken();
    const userInfo = this.getUserInfo();
    return token && userInfo;
  }

  clear() {
    storage.remove(AuthKeys.TOKEN);
    storage.remove(AuthKeys.USER_INFO);
  }
}

const auth = new Auth();
export default auth;
