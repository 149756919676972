import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';
import Popper from '@material-ui/core/Popper';
import { withRouter } from 'react-router-dom';

// @material-ui/icons
import Person from '@material-ui/icons/Person';
// import Dashboard from '@material-ui/icons/Dashboard';
import Search from '@material-ui/icons/Search';

import Swal from 'sweetalert2';

// core components
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import auth from 'utils/auth';
import history from 'utils/history';

import adminNavbarLinksStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx';

class HeaderLinks extends React.Component {
  state = {
    openNotification: false,
    openProfile: false,
    bookingId: null,
  };

  handleClickNotification = () => {
    this.setState({ openNotification: !this.state.openNotification });
  };

  handleCloseNotification = () => {
    this.setState({ openNotification: false });
  };

  handleClickProfile = () => {
    this.setState({ openProfile: !this.state.openProfile });
  };

  handleCloseProfile = () => {
    this.setState({ openProfile: false });
  };

  handleLogout = () => {
    // this.setState({ openProfile: false });
    Swal.fire({
      title: 'คุณต้องการออกจากระบบหรือไม่',
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.value) {
        auth.clear();
        history.replace('/auth/login-page');
        window.location.reload();
      }
    })
  };

  handleSearchBookingId = () => {
    if(!this.state.bookingId){
      Swal.fire({
        title: 'กรุณากรอก Booking ID',
        type: 'warning',
      })
    }else{
      this.props.history.push(`/admin/booking/bid/${this.state.bookingId}`)
    }
  }

  render() {
    const { classes, rtlActive } = this.props;
    const { openNotification, openProfile } = this.state;
    const isAuthenticated = auth.isAuthenticated();
    const searchButton =
      classes.top +
      ' ' +
      classes.searchButton +
      ' ' +
      classNames({
        [classes.searchRTL]: rtlActive,
      });
    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover,
      { [classes.dropdownItemRTL]: rtlActive },
    );
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive,
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true,
    });
    return (
      <div className={wrapper}>
        <CustomInput
          rtlActive={rtlActive}
          formControlProps={{
            className: classes.top + ' ' + classes.search,
          }}
          inputProps={{
            onChange: (e) => {
              this.setState({bookingId: e.target.value})
            },
            placeholder: rtlActive ? 'بحث' : 'Search Booking ID',
            inputProps: {
              'aria-label': rtlActive ? 'بحث' : 'Search Booking ID',
              className: classes.searchInput,
            },
          }}
        />
        <Button
          color="white"
          aria-label="edit"
          justIcon
          round
          className={searchButton}
          onClick={this.handleSearchBookingId}
          // onClick={() => {
          //   this.props.history.push(`/admin/booking/bid/${this.state.bookingId}`)
          // }}
        >
          <Search
            className={classes.headerLinksSvg + ' ' + classes.searchIcon}
          />
        </Button>
        {/* <Button
          color="transparent"
          simple
          aria-label="Dashboard"
          justIcon
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : '',
          }}>
          <Dashboard
            className={
              classes.headerLinksSvg +
              ' ' +
              (rtlActive
                ? classes.links + ' ' + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>
              {rtlActive ? 'لوحة القيادة' : 'Dashboard'}
            </span>
          </Hidden>
        </Button>
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={openNotification ? 'notification-menu-list' : null}
            aria-haspopup="true"
            onClick={this.handleClickNotification}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : '',
            }}
            buttonRef={node => {
              this.anchorNotification = node;
            }}>
            <Notifications
              className={
                classes.headerLinksSvg +
                ' ' +
                (rtlActive
                  ? classes.links + ' ' + classes.linksRTL
                  : classes.links)
              }
            />
            <span className={classes.notifications}>5</span>
            <Hidden mdUp implementation="css">
              <span
                onClick={this.handleClickNotification}
                className={classes.linkText}>
                {rtlActive ? 'إعلام' : 'Notification'}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={openNotification}
            anchorEl={this.anchorNotification}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openNotification,
              [classes.popperResponsive]: true,
              [classes.popperNav]: true,
            })}>
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="notification-menu-list"
                style={{ transformOrigin: '0 0 0' }}>
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleCloseNotification}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={this.handleCloseNotification}
                        className={dropdownItem}>
                        {rtlActive
                          ? 'إجلاء أوزار الأسيوي حين بل, كما'
                          : 'Mike John responded to your email'}
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleCloseNotification}
                        className={dropdownItem}>
                        {rtlActive
                          ? 'شعار إعلان الأرضية قد ذلك'
                          : 'You have 5 new tasks'}
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleCloseNotification}
                        className={dropdownItem}>
                        {rtlActive
                          ? 'ثمّة الخاصّة و على. مع جيما'
                          : "You're now friend with Andrew"}
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleCloseNotification}
                        className={dropdownItem}>
                        {rtlActive ? 'قد علاقة' : 'Another Notification'}
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleCloseNotification}
                        className={dropdownItem}>
                        {rtlActive ? 'قد فاتّبع' : 'Another One'}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div> */}

        <div className={managerClasses}>
          <Button
            color="transparent"
            aria-label="Person"
            justIcon
            aria-owns={openNotification ? 'profile-menu-list' : null}
            aria-haspopup="true"
            onClick={this.handleClickProfile}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : '',
            }}
            buttonRef={node => {
              this.anchorProfile = node;
            }}>
            <Person
              className={
                classes.headerLinksSvg +
                ' ' +
                (rtlActive
                  ? classes.links + ' ' + classes.linksRTL
                  : classes.links)
              }
            />
            <Hidden mdUp implementation="css">
              <span
                onClick={this.handleClickProfile}
                className={classes.linkText}>
                {rtlActive ? 'الملف الشخصي' : 'Profile'}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={openProfile}
            anchorEl={this.anchorProfile}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openProfile,
              [classes.popperResponsive]: true,
              [classes.popperNav]: true,
            })}>
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list"
                style={{ transformOrigin: '0 0 0' }}>
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleCloseProfile}>
                    <MenuList role="menu">
                      {/* <MenuItem
                        onClick={this.handleCloseProfile}
                        className={dropdownItem}>
                        {rtlActive ? 'الملف الشخصي' : 'Profile'}
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleCloseProfile}
                        className={dropdownItem}>
                        {rtlActive ? 'الإعدادات' : 'Settings'}
                      </MenuItem>
                      <Divider light /> */}
                      {isAuthenticated && (
                        <MenuItem
                          onClick={this.handleLogout}
                          className={dropdownItem}>
                          {rtlActive ? 'الخروج' : 'Log out'}
                        </MenuItem>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
};

const headerlinks = withRouter(HeaderLinks)
export default withStyles(adminNavbarLinksStyle)(headerlinks);
