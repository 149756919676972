import configDevelopment from './config.development';
import configProduction from './config.production';
import configStaging from './config.staging';

const env = process.env.REACT_APP_ENV || 'development';
console.log('Environment ->', env);

const setupConfig = env => {
  switch (env) {
    case 'production':
      return configProduction;
    case 'staging':
      return configStaging;
    default:
      return configDevelopment;
  }
};

const config = {
  ...setupConfig(env),
  publicUrl: process.env.PUBLIC_URL,
};

export default config;
