import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import moment from 'moment';
import { Provider } from 'mobx-react';
import AuthLayout from 'layouts/Auth.jsx';
import AdminLayout from 'layouts/Admin.jsx';
import DemoLayout from 'layouts/Demo.jsx';
import allStores from 'store/AllStore';
import 'moment/locale/th';
import 'assets/scss/material-dashboard-pro-react.scss?v=1.7.0';

const hist = createBrowserHistory();

moment.locale('th');

ReactDOM.render(
  <Provider {...allStores}>
    <Router history={hist} forceRefresh={true}>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <Route path="/admin" component={AdminLayout} />
        <Route path="/demo" component={DemoLayout} />
        <Redirect from="/" to="/auth/login-page" />
        {/* <Redirect to="/auth/error-page" /> */}
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root'),
);
