/*eslint-disable*/
import React from "react";
import { observer, inject } from 'mobx-react';
import { toJS } from "mobx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from 'components/CustomButtons/Button.jsx';
import DateFnsUtils from '@date-io/date-fns';
import Contacts from '@material-ui/icons/Contacts'
import ReactTable from "react-table";
import Swal from 'sweetalert2';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
    }
  }

  setDate(date, name){
    this.setState({
      [name]: date
    })
  }

  async componentDidMount() {
   await this.props.contact.loadContact();
  }

  async searchByCondition(startDate, endDate){
    try{
      await this.props.contact.loadContact(startDate, endDate);
    }catch(err){
      await Swal.fire({
        type: 'error',
        title: err.message,
      });
    }
  }
  
  render() {
    const { contact } = toJS(this.props.contact);
    const { startDate, endDate } = this.state;
    const column = [
      { Header: "Name", accessor: "name" },
      { Header: "Mobile No", accessor: "tel" },
      { Header: "Email", accessor: "email" },
      { Header: "TiTle", accessor: "title" },
      { Header: "Detail", accessor: "detail" },
      { Header: "Created At", accessor: "createdAt" },
    ]
    const { classes } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Contacts />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Contact</h4>
              <GridContainer justify="center" alignItems="center">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <GridItem>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="startDate"
                name="startDate"
                label="Start Date"
                value={startDate}
                onChange={(date) => this.setDate(date, 'startDate')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </GridItem>
            <GridItem>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="endDate"
                name="endDate"
                label="End Date"
                value={endDate}
                onChange={(date) => this.setDate(date, 'endDate')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </GridItem>
            <GridItem>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                // endIcon={<Icon>send</Icon>}
                onClick={() => this.searchByCondition(startDate, endDate)}
              >
                Send
              </Button>
            </GridItem>
          </MuiPickersUtilsProvider>
        </GridContainer>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={contact || []}
                filterable
                columns={column}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const style = {
  ...validationFormsStyle,
  tmp: {
    backGroundColor: '#000'
  }
}

Contact.propTypes = {
  classes: PropTypes.object
};

export const page = inject('contact')(observer(Contact));
export default withStyles(style)(page);
